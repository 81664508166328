<div>
    <div class="about--header">
        <h1> {{ 'about.title' | translate }} </h1>
    </div>
    <div class="about--content">
        <p>
            <img src="/assets/logos/rr.svg" height="100px">
        </p>
        <p>
            {{ 'general.version' | translate }}: {{ version }}
        </p>
        <p>
            {{ 'general.copyright' | translate }}
        </p>
    </div>
</div>
