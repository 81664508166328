import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LoggingLevel } from '../_classes/logging-level';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {

  private _loggingLevel: LoggingLevel = LoggingLevel.None;

  constructor() {
    this._loggingLevel = environment.logging.level;
  }

  public log(message: any, loggingLevel: LoggingLevel, ...optionalParams: any[]): void {
    if (this.shouldLog(loggingLevel)) {
      switch (loggingLevel) {
        case LoggingLevel.Error:
          console.error(message, optionalParams);
          break;
        case LoggingLevel.Warning:
          console.warn(message, optionalParams);
          break;
        case LoggingLevel.Info:
          console.info(message, optionalParams);
          break;
        default:
          console.debug(message, optionalParams);
      }
    }
  }

  public logError(message: any, ...optionalParams: any[]): void {
    this.log(message, LoggingLevel.Error, optionalParams);
  }

  public logWarning(message: any, ...optionalParams: any[]): void {
    this.log(message, LoggingLevel.Warning, optionalParams);
  }

  public logInfo(message: any, ...optionalParams: any[]): void {
    this.log(message, LoggingLevel.Info, optionalParams);
  }

  public logVerbose(message: any, ...optionalParams: any[]): void {
    this.log(message, LoggingLevel.Verbose, optionalParams);
  }

  private shouldLog(loggingLevel: LoggingLevel): boolean {
    if (this._loggingLevel === LoggingLevel.None) {
      return false;
    } else if (this._loggingLevel === LoggingLevel.Info) {
      return loggingLevel === LoggingLevel.Info;
    } else if (this._loggingLevel === LoggingLevel.Warning) {
      return loggingLevel === LoggingLevel.Info || loggingLevel === LoggingLevel.Warning;
    } else if (this._loggingLevel === LoggingLevel.Error) {
      return loggingLevel === LoggingLevel.Info || loggingLevel === LoggingLevel.Warning || loggingLevel === LoggingLevel.Error;
    }
    return true;
  }
}
