import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AppRegPasswordCredentials } from 'src/app/_shared/_classes/app-reg-password-credentials';
import { AppRegistration } from 'src/app/_shared/_classes/app-registration';
import { ErrorDialogComponent } from 'src/app/_shared/_components/_dialogs/error-dialog/error-dialog.component';
import { Pending } from 'src/app/_shared/_interfaces/pending';
import { LandingZoneApiService } from 'src/app/_shared/_services/landing-zone-api.service';
import { environment } from 'src/environments/environment';

import { Table } from 'primeng/table';
import { ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';

export class AppRegistrationView {
  id: string;
  appId: string;
  displayName: string;
  createdDateTime: Date;
  secrets: Date;
  hasExpired: boolean;
  willExpireSoon: boolean;
  hasSecretes: boolean;
  appRegPasswordCredentials: AppRegPasswordCredentials[];
}

@Component({
  templateUrl: './manage-app-reg.component.html',
  styleUrls: ['./manage-app-reg.component.scss']
})
export class ManageAppRegComponent implements OnInit {

  public loadAppRegistrations: Pending<AppRegistration[]>;

  @ViewChild('allApplicationRegistrationViewsTablePN') allApplicationRegistrationViewsTablePN!: Table;
  @ViewChild('globalFilter') globalFilter!: ElementRef;
  public allApplicationRegistrationViewsLoading: boolean = true;
  public allApplicationRegistrationViews: AppRegistrationView[];
  public currentFilterValue: string = '';
  public noDataTitle: string;
  public deleteResult: Pending<boolean>;

  constructor(
    private _landingZoneApiService: LandingZoneApiService,
    private _dialogService: DialogService,
    private confirmationService: ConfirmationService) { }

  public ngOnInit(): void {
    this.loadAppRegistrations = this._landingZoneApiService.fetchAppRegistrations();
    this.loadData();
  }

  public clear(table: Table) {
    this.globalFilter.nativeElement.value = ''
    table.clear();
  }
  /*public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.currentFilterValue = filterValue;
    this.dataSource.filterPredicate = (data: any, filter) => {
      const dataStr =JSON.stringify(data).toLowerCase();
      return dataStr.indexOf(filter) != -1;
    }
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.noDataTitle = 'No entry for filter: ' + filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }*/

  public applyFilterApplicationRegistrationViewPN($event: any, matchMode: string) {
    this.currentFilterValue = ($event.target as HTMLInputElement)?.value.trim().toLowerCase();
    this.noDataTitle = `No entry for filter: ${this.currentFilterValue}`;
    this.allApplicationRegistrationViewsTablePN.filterGlobal(this.currentFilterValue, matchMode);
  }

  private loadData(): void {
    this.allApplicationRegistrationViewsLoading = true;
    this.loadAppRegistrations.data.subscribe(
      response => {
        this.allApplicationRegistrationViews = ManageAppRegComponent.aggregatePasswordEnds(response);
        this.allApplicationRegistrationViewsLoading = false;
        this.noDataTitle = `No entry for filter: ${this.currentFilterValue}`;

        if (this.allApplicationRegistrationViews.length == 0) {
          this.noDataTitle = 'No AppRegistrations available at this point in time';
        }

        if (environment.enableDebug) {
          console.log(`Start: loadAppRegistrations()`);
          console.log(response);
          console.log(this.allApplicationRegistrationViews);
          console.log(`End: loadAppRegistrations()`);
        }
      },
      error => {
        this.allApplicationRegistrationViewsLoading = false;
        this.showErrorMessage(error, 'App Registrations', 'load');
      }
    );
  }

  private static aggregatePasswordEnds(appRegistrations: AppRegistration[]): AppRegistrationView[] {

    let now = new Date();
    let thresholdDate = new Date(now.setMonth(now.getMonth() + 1));
    let aggregatedAppRegistrations = new Array<AppRegistrationView>();
    for (let app of appRegistrations) {

      let minDate = new Date();
      let noValue = true;
      let isFirstDate = true;
      for (let cred of app.appRegPasswordCredentials) {
        noValue = false;
        if (isFirstDate) {
          isFirstDate = false;
          minDate = cred.endDateTime;
        }
        else if (minDate > cred.endDateTime) {
          minDate = cred.endDateTime;
        }
      }

      let currentApp = new AppRegistrationView();

      currentApp.appId = app.appId;
      currentApp.appRegPasswordCredentials = app.appRegPasswordCredentials;
      currentApp.createdDateTime = app.createdDateTime;
      currentApp.displayName = app.displayName;
      currentApp.id = app.id;
      currentApp.secrets = noValue ? new Date(0) : minDate;
      currentApp.hasSecretes = !noValue;
      currentApp.hasExpired = new Date(minDate) < new Date();
      currentApp.willExpireSoon = thresholdDate > new Date(minDate);

      aggregatedAppRegistrations.push(currentApp);
    }
    return aggregatedAppRegistrations;
  }

  public onDeleteAppReg(appReg: any): void {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete this Application Registration?',
      accept: () => {
        this.deleteResult = this._landingZoneApiService.deleteAppRegistration(appReg.id);
        this.deleteResult.data.subscribe(
          response => {
            if (response) {
              this.allApplicationRegistrationViewsLoading = true;
              this.loadAppRegistrations = this._landingZoneApiService.fetchAppRegistrations();
              this.loadData();
            }
          },
          error => {
            this.allApplicationRegistrationViewsLoading = false;
            this.showErrorMessage(error, appReg.id, 'delete');
          });
        console.log('delete', appReg)
      }
    });
  }

  private showErrorMessage(error: any, displayName: string, action: string): void {
    let title = 'Failed to ' + action + ' Secrete: ' + displayName;
    let message: string;
    if (error instanceof HttpErrorResponse) {
      let errResponse = error as HttpErrorResponse;
      console.log('onDeleteSecret. delete call error:', errResponse.status);
      if (errResponse.status == 403) {
        message = 'You are not permitted';
      }
      else {
        message = 'unknown error';
      }

    }
    else {
      message = 'unknown error';
    }

    this._dialogService.open(ErrorDialogComponent, {
      data: {
        title: title,
        message: message
      }
    });
  }
}
