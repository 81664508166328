import { HomeComponent } from "./home.component";
import {RoleGuardService} from "../role-guard-service";
import auth from "../auth-config.json";

export const HOME_ROUTES = [
    {
      path: '',
      component: HomeComponent,
      canActivate: [RoleGuardService],
      data: {
          expectedTenants: auth.Tenants
        }
    }
];
