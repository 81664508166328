import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LayoutModule } from '@angular/cdk/layout';
import { MainComponent } from './main.component';

import { MaterialModule } from 'src/app/material.module';

// import ngx-translate and the http loader
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule} from '@angular/common/http';

import { CreateLandingZoneModule } from './create-landing-zone/create-landing-zone.module';
import { AboutModule } from './about/about.module';
import { ManageAppRegModule } from './manage-app-reg/manage-app-reg.module';

import {SidebarModule} from 'primeng/sidebar';
import {ButtonModule} from 'primeng/button';

@NgModule({
  declarations: [
    MainComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    LayoutModule,
    MaterialModule,
    HttpClientModule,
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    }),
    CreateLandingZoneModule,
    ManageAppRegModule,
    AboutModule,
    SidebarModule,
    ButtonModule
  ]
})
export class MainModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
