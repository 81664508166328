<div>
  <h1>Application Secret</h1>
  <div>
    <p-table [value]="appSecretsValueList">
      <ng-template pTemplate="header">
        <tr>
          <th>Field</th>
          <th>Value</th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-element>
        <tr>
          <td> {{element.field}} </td>
          <td> {{element.value}} </td>
          <td>
            <button class="p-button-secondary p-button-text" pButton pRipple pTooltip="copy"
              [cdkCopyToClipboard]="element.value">
              <i class="pi pi-copy"></i>
            </button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <p>
  <h3>Client secrets cannot be viewed, except for immediatley after creation. Be sure to copy the secret before leaving
    this window.</h3>
  <div>
    <button  pButton pRipple class="button p-button" (click)="close()" color="primary">Close</button>
  </div>
</div>
