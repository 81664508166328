<h1 class="ml-4 mt-3">{{ 'manage_app_reg.appRegList' | translate }}</h1>

<div style="max-height: 800px; overflow: auto;" class="mx-4">
  <p-table #allApplicationRegistrationViewsTablePN [value]="allApplicationRegistrationViews" dataKey="objectId"
    [rowHover]="true" [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]"
    [loading]="allApplicationRegistrationViewsLoading" responsiveLayout="stack" [paginator]="true"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [filterDelay]="0"
    [globalFilterFields]="['displayName', 'appId', 'id', 'createdDateTime', 'secrets']" styleClass="p-datatable-sm">
    <ng-template pTemplate="caption">
      <div class="flex">
        <button pButton label="Clear" class="p-button-outlined" icon="pi pi-filter-slash"
          (click)="clear(allApplicationRegistrationViewsTablePN)"></button>
        <span class="p-input-icon-left ml-auto">
          <i class="pi pi-search"></i>
          <input pInputText type="text" matchMode="contains" #globalFilter
            (input)="applyFilterApplicationRegistrationViewPN($event, 'contains')" placeholder="Search keyword" />
        </span>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="displayName">
          Display Name
          <p-sortIcon field="displayName"></p-sortIcon>
          <p-columnFilter type="text" field="displayName" display="menu"></p-columnFilter>
        </th>
        <th pSortableColumn="appId">
          Application Id
          <p-sortIcon field="appId"></p-sortIcon>
          <p-columnFilter type="text" field="appId" display="menu"></p-columnFilter>
        </th>
        <th pSortableColumn="id">
          Object Id
          <p-sortIcon field="id"></p-sortIcon>
          <p-columnFilter type="text" field="id" display="menu"></p-columnFilter>
        </th>
        <th pSortableColumn="createdDateTime">
          Created
          <p-sortIcon field="createdDateTime"></p-sortIcon>
          <p-columnFilter type="text" field="createdDateTime" display="menu"></p-columnFilter>
        </th>
        <th pSortableColumn="secrets">
          Expires
          <p-sortIcon field="secrets"></p-sortIcon>
          <p-columnFilter type="text" field="secrets" display="menu"></p-columnFilter>
        </th>
        <th>
          Delete
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-appRegView>
      <tr class="p-selectable-row">
        <td [routerLink]="['/manage-app-reg', appRegView.id]">
          <span class="p-column-title">Display Name</span>
          {{ appRegView.displayName }}
        </td>
        <td [routerLink]="['/manage-app-reg', appRegView.id]">
          <span class="p-column-title">Application Id</span>
          {{ appRegView.appId }}
        </td>
        <td [routerLink]="['/manage-app-reg', appRegView.id]">
          <span class="p-column-title">Object Id</span>
          {{ appRegView.id }}
        </td>
        <td [routerLink]="['/manage-app-reg', appRegView.id]">
          <span class="p-column-title">Created</span>
          {{ appRegView.createdDateTime | date:'dd/MM/YYYY' }}
        </td>
        <td [routerLink]="['/manage-app-reg', appRegView.id]">
          <span class="p-column-title">Expires</span>
          <ng-container *ngIf="appRegView.hasSecretes">
            <span
              [ngStyle]="{'color': appRegView.hasExpired ? 'red': appRegView.willExpireSoon ?  'orange' : 'green'}">{{
              appRegView.secrets | date:'dd/MM/YYYY' }}</span>
          </ng-container>
        </td>
        <td>
          <span class="p-column-title">Delete</span>
          <button (click)="onDeleteAppReg(appRegView)" class="p-button-secondary p-button-text" pButton pRipple
            pTooltip="delete">
            <i class="pi pi-trash"></i>
          </button>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="6">{{ noDataTitle }}</td>
      </tr>
    </ng-template>
  </p-table>
</div>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
