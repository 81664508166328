import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AppRegistration } from 'src/app/_shared/_classes/app-registration';
import { Guid } from 'src/app/_shared/_classes/guid';
import { PasswordCredential } from 'src/app/_shared/_classes/password-credential';
import { ErrorDialogComponent } from 'src/app/_shared/_components/_dialogs/error-dialog/error-dialog.component';
import { Pending } from 'src/app/_shared/_interfaces/pending';
import { LandingZoneApiService } from 'src/app/_shared/_services/landing-zone-api.service';
import { AppRegSecretDialogComponent } from './app-reg-secret-dialog.component';

@Component({
  selector: 'app-app-reg-create-secret',
  templateUrl: './app-reg-create-secret.component.html',
  styleUrls: ['./app-reg-create-secret.component.scss'],
  providers: [DialogService]
})
export class AppRegCreateSecretComponent implements OnInit {

  pageTitle: string = '';
  appRegId: string = '';
  isLoadingResults = true;
  loadAppReg: Pending<AppRegistration>;
  appReg: AppRegistration;
  public formGroup: FormGroup = new FormGroup({});
  public monthSelect = 6;
  post: any = '';
  createResult: Pending<PasswordCredential>;

  dialogref: DynamicDialogRef;

  months = [{ name: '6 month', value: 6 }, { name: '12 month', value: 12 }];

  constructor(
    private _route: ActivatedRoute,
    private _landingZoneApiService: LandingZoneApiService,
    private _formBuilder: FormBuilder,
    public dialogService: DialogService
  ) { }

  public ngOnInit(): void {
    this.monthSelect = 6;
    this.appRegId = this._route.snapshot.paramMap.get('id') as string;
    this.pageTitle = "Secrets for App Reg: ";
    this.isLoadingResults = true;
    this.loadAppReg = this._landingZoneApiService.fetchAppRegistration(this.appRegId);
    this.loadData();
    this.createForm();
  }

  private loadData(): void {
    this.loadAppReg.data.subscribe(
      response => {
        this.appReg = response;
        this.isLoadingResults = false;
        this.pageTitle = "Add Secret for App Reg: " + this.appReg.displayName;
      }
    );
  }

  public createForm(): void {
    this.formGroup = this._formBuilder.group({
      'secretDisplayName': [null, Validators.required],
      'monthSelect': [6, Validators.required]
    });
  }


  public onSubmit(post: any): void {
    this.post = post;
    console.log('submit App Secret:', this.post);
    this.generateSecret(post.secretDisplayName);
  }

  public generateSecret(newSecretName: string): void {
    console.log('months', this.formGroup.get('monthSelect')?.value);
    let monthSelection = this.formGroup.get('monthSelect')?.value;
    let newSecretKey = Guid.newGuid();
    let now = new Date();
    let ends: Date;
    let starts: Date;
    starts = new Date();
    ends = new Date(now
      .setMonth(now.getMonth() + (monthSelection || 12)))

    this.createResult = this._landingZoneApiService.createAppRegistrationPassword(this.appRegId,
      newSecretKey,
      newSecretName,
      starts,
      ends);

    this.createResult.data.subscribe(
      response => {
        this.isLoadingResults = true;
        this.loadAppReg = this._landingZoneApiService.fetchAppRegistration(this.appRegId);


        this.dialogService.open(AppRegSecretDialogComponent, {
          data: {
            appRegName: this.appReg.displayName,
            secret: response.secretText,
            secretId: newSecretKey,
            secretName: newSecretName,
            appRegId: this.appReg.appId,
            startDate: response.startDateTime,
            endDate: response.endDateTime

          }
        });
        this.loadData();
      },
      error => {
        this.showErrorMessage(error, newSecretName, 'add secret');
      });
  }

  private showErrorMessage(error: any, displayName: string, action: string): void {
    let title = 'Failed to ' + action + ' Secret: ' + displayName;
    let message: string;
    if (error instanceof HttpErrorResponse) {
      let errResponse = error as HttpErrorResponse;
      if (errResponse.status == 403) {
        message = 'You are not permitted';
      }
      else {
        message = 'unknown error';
      }

    }
    else {
      message = 'unknown error';
    }

    this.dialogService.open(ErrorDialogComponent, {
      data: {
        title: title,
        message: message
      }
    });
  }
}
