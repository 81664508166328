import { Component, OnInit } from '@angular/core';
import appVersion from 'src/assets/app-version.json';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
})
export class AboutComponent implements OnInit {
  public version: string = '';

  constructor() {}

  ngOnInit(): void {
    this.version = appVersion.semanticVersion;
  }
}
