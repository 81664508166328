import { MsalGuard } from '@azure/msal-angular';

import { appRouteNames } from './app.routes.names';

import { MAIN_ROUTES } from './main/main.routes';

export const APP_ROUTES = [
    {
        path: appRouteNames.MAIN,
        children: MAIN_ROUTES,
        canActivate: [
            MsalGuard
        ]
    },
    {
        path: '**', // 404: Not Found
        redirectTo: appRouteNames.MAIN
    },
    {
        path: '',
        pathMatch: 'full',
        redirectTo: appRouteNames.MAIN
    }
];
