<form [formGroup]="form">
  <span class="mb-5 mt-5">
    <label class="p-2" for="{{stageName + '_Reader'}}">{{ 'create_landing_zone.permissions.reader' | translate}}</label>
    <p-autoComplete appendTo="body" id="{{stageName + '_Reader'}}" class="w-100" inputStyleClass="w-100"
      styleClass="w-100" id="float-input" formControlName='{{reader}}' [suggestions]="userSuggestions"
      (completeMethod)="search($event)" [forceSelection]="true" field="email" [multiple]="true" placeholder="Type here to search for user">
      <ng-template let-user pTemplate="item">
        <div>
          <div>{{user.displayName}}</div>
          <small>{{user.email}}</small>
        </div>
      </ng-template>
    </p-autoComplete>

  </span>

  <span class="mt-5">
    <label class="p-2" for="{{stageName + '_Contributor'}}">{{ 'create_landing_zone.permissions.contributor' | translate}}</label>
    <p-autoComplete appendTo="body" id="{{stageName + '_Contributor'}}" class="w-100" inputStyleClass="w-100"
      styleClass="w-100" id="float-input" formControlName='{{contributor}}' [suggestions]="userSuggestions"
      (completeMethod)="search($event)" [forceSelection]="true" field="email" [multiple]="true" placeholder="Type here to search for user">
      <ng-template let-user pTemplate="item">
        <div>
          <div>{{user.displayName}}</div>
          <small>{{user.email}}</small>
        </div>
      </ng-template>
    </p-autoComplete>
  </span>
</form>
