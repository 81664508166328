import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core'
import { Language } from '../_classes/language';
import { Pending, PendingStatus } from '../_interfaces/pending';
import { TranslateService } from '@ngx-translate/core';
import { ReplaySubject, defer, Subject } from 'rxjs';
import { retry, catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  languages: Language[] = [];
  currentLanguage: Language | undefined;
  currentLang: Subject<Language> = new Subject<Language>();

  constructor(
    private http: HttpClient,
    private translate: TranslateService
  ) { }

  public setDefaultLang(lang: string): void {
    this.translate.setDefaultLang(lang);
  }

  public getCurrentLanguage(): Language {
    let currentLang = this.languages.find(lang => lang.iso == localStorage.getItem(Language.LANG_LOCAL_STORAGE_ITEM));
    if (currentLang != undefined) {
      return currentLang;
    }
    currentLang = this.languages.find(lang => lang.iso == this.translate.getDefaultLang());
    if (currentLang != undefined) {
      return currentLang;
    }
    return Language.STD_LANG_OBJ;
  }

  public setCurrentLanguage(lang: Language): void {
    this.currentLanguage = lang;
    this.currentLang.next(this.currentLanguage);
    this.translate.use(lang.iso);
    document.documentElement.lang = lang.iso;
    localStorage.setItem(Language.LANG_LOCAL_STORAGE_ITEM, lang.iso);
  }

  public setCurrentLang(lang: string): void {
    this.currentLanguage = this.languages.find(
      language => language.iso === lang
    );
    if (this.currentLanguage === undefined) {
      this.currentLanguage = {iso: 'en', value: 'English'};
    }
    this.currentLang.next(this.currentLanguage);
    this.translate.use(this.currentLanguage.iso);
    document.documentElement.lang = this.currentLanguage.iso;
    localStorage.setItem(Language.LANG_LOCAL_STORAGE_ITEM, lang);
  }

  public fetchLanguages(): Pending<Language[]> {
    const status = new ReplaySubject<PendingStatus>();
    const request = this.http.get<Language[]>(`assets/i18n/langs.json`)
      .pipe(
        retry(2),
        catchError(
          error => {
            status.next(PendingStatus.ERROR);
            throw error;
          },
        ),
        tap(
          response => {
            this.languages = response;
            status.next(PendingStatus.SUCCESS);
          }
        )
      );

    const data = defer(
      () => {
        status.next(PendingStatus.LOADING);
        return request;
      }
    );

    return { data, status };
  }
}
