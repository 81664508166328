import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { MatSidenav } from '@angular/material/sidenav';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';
import { LoggingService } from '../_shared/_services/logging.service';
import appVersion from 'src/assets/app-version.json';

import auth from './auth-config.json';

type IdTokenClaims = {
  roles: string[];
}


@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSidenav) sidenav: MatSidenav;

  public activeAccount: AccountInfo | null;
  public display = false;
  public version: string = '';
  isExpanded = false;
  //events: string[] = [];
  //opened: boolean = true;

  //shouldRun = [/(^|\.)plnkr\.co$/, /(^|\.)stackblitz\.io$/].some(h => h.test(window.location.host));

  isHandset$: Observable<boolean> = this._breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(
    private _breakpointObserver: BreakpointObserver,
    private _cdr: ChangeDetectorRef,
    private _msalService: MsalService,
    private _logger: LoggingService
  ) { }

  ngAfterViewInit(): void {
    this._cdr.detectChanges();

    this._breakpointObserver.observe(['(max-width: 800px)']).subscribe((res) => {
      if (res.matches) {
        this.sidenav.mode = 'over';
        this.sidenav.close();
      } else {
        this.sidenav.mode = 'side';
        this.sidenav.open();
      }
    });

  }

  ngOnInit(): void {
    this.activeAccount = this._msalService.instance.getActiveAccount();
    this._logger.logInfo(this.activeAccount);
    this.version = appVersion.semanticVersion;
  }

  public logout(): void {
    this._msalService.logout();
  }

  get isAppRegUser(): boolean {
    if (this._msalService.instance.getAllAccounts() === null) {
      return false;
    }
    if (this._msalService.instance.getAllAccounts().length == 0) {
      return false;
    }

    return MainComponent.checkRole(this._msalService.instance.getAllAccounts()[0], auth.roles.AppRegistrationUser);
  }

  get isLandingZoneUser(): boolean {
    if (this._msalService.instance.getAllAccounts() === null) {
      return false;
    }
    if (this._msalService.instance.getAllAccounts().length == 0) {
      return false;
    }

    return MainComponent.checkRole(this._msalService.instance.getAllAccounts()[0], auth.roles.LandingZoneUser);
  }

  private static checkRole(accountInfo: AccountInfo, role: string): boolean {
    let claims = accountInfo.idTokenClaims as IdTokenClaims;
    return claims.roles ? claims.roles.includes(role) : false;
  }

  public toggleMenu() {
    if (this.sidenav.mode == 'side') {
      this.isExpanded = !this.isExpanded;
    }
    if (this.sidenav.mode == 'over') {
      this.isExpanded = true;
      this.sidenav.opened ? this.sidenav.close() : this.sidenav.open();
    }
  }

}
