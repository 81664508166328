import { Component, OnInit } from '@angular/core';
import { Pending} from './_shared/_interfaces/pending';
import { Language } from './_shared/_classes/language';
import { LanguageService } from './_shared/_services/language.service';
import { environment } from 'src/environments/environment';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public readonly loadLanguages: Pending<Language[]>;

  constructor(
    private _languageService: LanguageService,
    private _msalService: MsalService,
    private _msalBroadcastService: MsalBroadcastService
  ) {
    this._languageService.setDefaultLang(environment.stdLang);
    this.loadLanguages = this._languageService.fetchLanguages();
  }

  ngOnInit(): void {
    registerLocaleData(localeDe, 'de-DE', localeDeExtra);
    this.loadLanguages.data.subscribe(
      (response) => {
        this._languageService.languages = response;
        let localStrLang = this._languageService.languages.find(lang => lang.iso == localStorage.getItem(Language.LANG_LOCAL_STORAGE_ITEM));
        let localLang = this._languageService.languages.find(lang => lang.iso == navigator.language.split('-')[0]);

        if (localStrLang != undefined) {
          this._languageService.setCurrentLang(localStrLang.iso);
        } else if (localLang != undefined) {
          this._languageService.setCurrentLang(localLang.iso);
        } else {
          this._languageService.setCurrentLang(environment.stdLang);
        }
      }
    );

    this._msalBroadcastService.inProgress$.subscribe(
      () => {
        /**
        * If no active account set but there are accounts signed in, sets first account to active account
        * To use active account set here, subscribe to inProgress$ first in your component
        * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
        */
         let activeAccount = this._msalService.instance.getActiveAccount();

         if (!activeAccount && this._msalService.instance.getAllAccounts().length > 0) {
           let accounts = this._msalService.instance.getAllAccounts();
           this._msalService.instance.setActiveAccount(accounts[0]);
         }
      }
    );
  }
}
