import { MsalGuard } from "@azure/msal-angular";
import { RoleGuardService } from "../role-guard-service";
import { AppRegCreateSecretComponent } from "./app-reg-create-secret.component";
import { AppRegDetailComponent } from "./app-reg-detail.component";
import { ManageAppRegComponent } from "./manage-app-reg.component";

import auth from '../auth-config.json';

export const MANAGE_APP_REG_ROUTES = [
  {
    path: '',
    component: ManageAppRegComponent,
    canActivate: [
      MsalGuard,
      RoleGuardService
    ],
    data: {
      expectedRole: auth.roles.AppRegistrationUser,
      expectedTenants: auth.Tenants
    }
  },
  {
    path: ':id',
    component: AppRegDetailComponent,
    canActivate: [
      MsalGuard,
      RoleGuardService
    ],
    data: {
      expectedRole: auth.roles.AppRegistrationUser,
      expectedTenants: auth.Tenants
    }
  },
  {
    path: ':id/create',
    component: AppRegCreateSecretComponent,
    canActivate: [
      MsalGuard,
      RoleGuardService
    ],
    data: {
      expectedRole: auth.roles.AppRegistrationUser,
      expectedTenants: auth.Tenants
    }
  }
];
