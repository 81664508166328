<div class="p-4 w-100">
  <h1 class="pageHeading">{{ 'home.heading' | translate }}</h1>
  <h3 [innerHTML]="'home.subheading' | translate">
  </h3>

  <div class="cardContainer">
    <mat-card class="example-card" routerLink="/create-landing-zone" *ngIf="isLandingZoneUser">
      <mat-card-header>
        <div mat-card-avatar class="example-header-image">
          <div class="cardIcon">
            <mat-icon aria-label="Side nav toggle icon">
              flight_land
            </mat-icon>
          </div>
        </div>
        <mat-card-title>{{ 'create_landing_zone.title' | translate }}</mat-card-title>
      </mat-card-header>
      <mat-card-content class="cardContent">
        <div [innerHTML]="'create_landing_zone.card_content' | translate">
        </div>

      </mat-card-content>
      <mat-card-actions>
        <button mat-button class="moreButton" routerLink="/create-landing-zone">MORE</button>
      </mat-card-actions>
    </mat-card>

    <mat-card class="example-card  mr-4" routerLink="/manage-app-reg" *ngIf="isAppRegUser">
      <mat-card-header>
        <div mat-card-avatar class="example-header-image">
          <div class="cardIcon">
            <mat-icon aria-label="Side nav toggle icon">
              vpn_key
            </mat-icon>
          </div>
        </div>
        <mat-card-title>{{ 'manage_app_reg.title' | translate }}</mat-card-title>
      </mat-card-header>
      <mat-card-content class="cardContent">
        <div [innerHTML]="'manage_app_reg.card_content' | translate">
        </div>

      </mat-card-content>
      <mat-card-actions>
        <button mat-button routerLink="/manage-app-reg" class="moreButton">MORE</button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
