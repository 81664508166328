import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AutocompleteUser } from 'src/app/_shared/_classes/autocompleteUser';
import { LandingZoneApiService } from 'src/app/_shared/_services/landing-zone-api.service';

@Component({
  selector: 'app-stage-permissions',
  templateUrl: './stage-permissions.component.html',
  styleUrls: ['./stage-permissions.component.scss']
})
export class StagePermissionsComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() stageName: string;

  public reader: string = '_Reader';
  public contributor: string = '_Contributor';

  public userSuggestions: AutocompleteUser[] = [];

  constructor(
    private _landingZoneApiService: LandingZoneApiService
  ) { }

  ngOnInit(): void {
    this.contributor = this.stageName + this.contributor;
    this.reader = this.stageName + this.reader;
  }

  search(event: any) {
    let searchTerm = event.query;
    this._landingZoneApiService.autoCompleteUser(searchTerm).data.subscribe((users) => {
      this.userSuggestions = users;
    });
  }
}
