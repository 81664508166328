import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { defer, ReplaySubject } from 'rxjs';
import { catchError, retry, tap } from 'rxjs/operators';
import { Language } from '../_classes/language';
import { Pending, PendingStatus } from '../_interfaces/pending';
import { LanguageService } from './language.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  public constructor(
    private _http: HttpClient,
    private _languageService: LanguageService
  ) { }

  public fetchData<T>(name: string): Pending<T> {
    const status = new ReplaySubject<PendingStatus>();
    const request = this._http.get<T>(`assets/i18n/${name}/${localStorage.getItem(Language.LANG_LOCAL_STORAGE_ITEM) ? localStorage.getItem(Language.LANG_LOCAL_STORAGE_ITEM) : this._languageService.getCurrentLanguage().iso}.json`)
      .pipe(
        retry(2),
        catchError(
          error => {
            status.next(PendingStatus.ERROR);
            throw error;
          },
        ),
        tap(
          response => {
            status.next(PendingStatus.SUCCESS);
            return response;
          }
        )
      );

    const data = defer(
      () => {
        status.next(PendingStatus.LOADING);
        return request;
      }
    );

    return { data, status };
  }
}
