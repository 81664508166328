import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppRegPasswordCredentials } from 'src/app/_shared/_classes/app-reg-password-credentials';
import { AppRegistration } from 'src/app/_shared/_classes/app-registration';
import { PasswordCredential } from 'src/app/_shared/_classes/password-credential';
import { ErrorDialogComponent } from 'src/app/_shared/_components/_dialogs/error-dialog/error-dialog.component';
import { Pending } from 'src/app/_shared/_interfaces/pending';
import { LandingZoneApiService } from 'src/app/_shared/_services/landing-zone-api.service';
import { AppRegSecretDialogComponent } from './app-reg-secret-dialog.component';

import { Table } from 'primeng/table';
import { ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';

export class AppRegPasswordCredentialsExtended {
  hint: string;
  keyId: string;
  displayName: string;
  startDateTime: Date;
  endDateTime: Date;
  hasExpired: boolean;
  willExpireSoon: boolean;
}

@Component({
  templateUrl: './app-reg-detail.component.html',
  styleUrls: ['./app-reg-detail.component.scss']
})
export class AppRegDetailComponent implements OnInit, AfterViewInit {

  @ViewChild('allAggregatedAppRegSecretsTablePN') allAggregatedAppRegSecretsTablePN!: Table;

  public pageTitle: string = "";
  public noDataTitle: string = "";
  public loadAppReg: Pending<AppRegistration>;
  public allAggregatedAppRegSecretsLoading: boolean = true;
  public allAggregatedAppRegSecrets: AppRegPasswordCredentialsExtended[];

  public appReg: AppRegistration;
  public deleteResult: Pending<boolean>;
  public createResult: Pending<PasswordCredential>;

  public appRegId: string = '';

  constructor(
    private _route: ActivatedRoute,
    private _landingZoneApiService: LandingZoneApiService,
    public dialogService: DialogService,
    private confirmationService: ConfirmationService
  ) { }

  public ngOnInit(): void {
    this.appRegId = this._route.snapshot.paramMap.get('id') as string;
    this.pageTitle = "Secrets for App Reg: ";
    this.allAggregatedAppRegSecretsLoading = true;
    this.loadAppReg = this._landingZoneApiService.fetchAppRegistration(this.appRegId);
    this.loadData();
  }

  public clear(table: Table) {
    table.clear();
  }

  public onGenerateSecret(secret: any): void {
    this.confirmationService.confirm({
      message: `Are you sure that you want to renew the secret "${secret.displayName}"?`,
      accept: () => {
        this.deleteResult = this._landingZoneApiService.deleteAppRegistrationPassword(this.appRegId, secret.keyId);
        this.deleteResult.data.subscribe(
          response => {
            if (response) {
              this.allAggregatedAppRegSecretsLoading = true;
              let now = new Date();
              this.createResult = this._landingZoneApiService.createAppRegistrationPassword(this.appRegId,
                secret.keyId,
                secret.displayName,
                new Date(),
                new Date(now
                  .setMonth(now.getMonth() + 8)));

              this.createResult.data.subscribe(
                response => {
                  this.allAggregatedAppRegSecretsLoading = true;
                  this.loadAppReg = this._landingZoneApiService.fetchAppRegistration(this.appRegId);
                  this.loadData();

                  this.dialogService.open(AppRegSecretDialogComponent, {
                    data: {
                      appRegName: this.appReg.displayName,
                      secret: response.secretText,
                      secretId: secret.keyId,
                      secretName: secret.displayName,
                      appRegId: this.appReg.appId,
                      startDate: response.startDateTime,
                      endDate: response.endDateTime
                    }
                  });
                },
                error => {
                  this.ShowErrorMessage(error, secret.displayName, 'renew');
                });
            }
          },
          error => {
            this.allAggregatedAppRegSecretsLoading = false;
            this.ShowErrorMessage(error, secret.displayName, 'renew');
          });
      }
    });
  }

  public onDeleteSecret(secret: any): void {
    this.confirmationService.confirm({
      message: `Are you sure that you want to delete the secret "${secret.displayName}"?`,
      accept: () => {
        this.deleteResult = this._landingZoneApiService.deleteAppRegistrationPassword(this.appRegId, secret.keyId);
        this.deleteResult.data.subscribe(
          response => {
            if (response) {
              this.allAggregatedAppRegSecretsLoading = true;
              this.loadAppReg = this._landingZoneApiService.fetchAppRegistration(this.appRegId);
              this.loadData();
            }
          },
          error => {
            this.allAggregatedAppRegSecretsLoading = false;
            this.ShowErrorMessage(error, secret.displayName, 'delete');
          });
      }
    });
  }

  private ShowErrorMessage(error: any, displayName: string, action: string): void {
    let title = `Failed to ${action} Secret: ${displayName}`;
    let message: string = '';
    if (error instanceof HttpErrorResponse) {
      let errResponse = error as HttpErrorResponse;
      if (errResponse.status == 403) {
        message = 'You are not permitted';
      }
      else {
        message = 'unknown error';
      }

    }
    else {
      message = 'unknown error';
    }

    this.dialogService.open(ErrorDialogComponent, {
      data: {
        title: title,
        message: message
      }
    });
  }

  public applyFilterApplicationRegistrationSecretsPN($event: any, matchMode: string) {
    let value = ($event.target as HTMLInputElement)?.value.trim().toLowerCase();
    this.noDataTitle = `No entry for filter: ${value}`;
    this.allAggregatedAppRegSecretsTablePN.filterGlobal(value, matchMode);
  }

  public ngAfterViewInit() {
    this.appRegId = this._route.snapshot.paramMap.get('id') as string;
  }

  private loadData(): void {
    this.loadAppReg.data.subscribe(
      response => {
        this.appReg = response;
        this.allAggregatedAppRegSecrets = AppRegDetailComponent.setExpirationFlags(this.appReg.appRegPasswordCredentials);
        this.allAggregatedAppRegSecretsLoading = false;
        this.pageTitle = `Secrets for App Reg: ${this.appReg.displayName}`;
        if (this.appReg.appRegPasswordCredentials.length == 0) {
          this.noDataTitle = `The AppReg: ${this.appReg.displayName} has no secrets`;
        }
      }
    );
  }

  private static setExpirationFlags(appRegSecrets: AppRegPasswordCredentials[]): AppRegPasswordCredentialsExtended[] {
    let now = new Date();
    let thresholdDate = new Date(now.setMonth(now.getMonth() + 1));
    let aggregatedAppRegistrationSecrets = new Array<AppRegPasswordCredentialsExtended>();
    for (let appRegSecret of appRegSecrets) {
      let currentAppSecret = new AppRegPasswordCredentialsExtended();
      currentAppSecret.keyId = appRegSecret.keyId;
      currentAppSecret.displayName = appRegSecret.displayName;
      currentAppSecret.endDateTime = appRegSecret.endDateTime;
      currentAppSecret.hint = appRegSecret.hint;
      currentAppSecret.startDateTime = appRegSecret.startDateTime;
      currentAppSecret.hasExpired = new Date() > new Date(appRegSecret.endDateTime);
      currentAppSecret.willExpireSoon = thresholdDate > new Date(appRegSecret.endDateTime);
      aggregatedAppRegistrationSecrets.push(currentAppSecret);
    }
    return aggregatedAppRegistrationSecrets;
  }


  getAppOwners() {
    return this.appReg.owners.map(o => o.displayName + ' (' + o.userPrincipalName + ')').join(';');
  }

}
