<h1>Application Registrations <i class="pi pi-angle-right"></i> Secrets for App Reg</h1>

<div *ngIf="appReg" class="mb-4">
  <h3>App Details</h3>
  <table>
    <tr>
      <td class="detailCellHeader"><b>App Name</b></td>
      <td>{{appReg.displayName}}</td>
    </tr>
    <tr>
      <td class="detailCellHeader"><b>App Owner</b></td>
      <td>{{getAppOwners()}}</td>
    </tr>
    <tr>
      <td class="detailCellHeader"><b>App Notes</b></td>
      <td>{{appReg.notes}}</td>
    </tr>
  </table>
</div>

<h3>App Secrets</h3>
<div style="max-height: 800px; overflow: auto;">
  <p-table #allAggregatedAppRegSecretsTablePN [value]="allAggregatedAppRegSecrets" dataKey="keyId" [rowHover]="true"
    [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]"
    [loading]="allAggregatedAppRegSecretsLoading" responsiveLayout="stack" [paginator]="true"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [filterDelay]="0"
    [globalFilterFields]="['displayName', 'keyId', 'hint', 'endDateTime']" [scrollable]="true" scrollHeight="519px"
    styleClass="p-datatable-sm">
    <ng-template pTemplate="caption">
      <div class="flex">
        <div>
          <button pButton label="Clear" class="p-button-outlined" icon="pi pi-filter-slash"
            (click)="clear(allAggregatedAppRegSecretsTablePN)"></button>
          <button pButton icon="pi pi-plus-circle" [routerLink]="['/manage-app-reg', appRegId, 'create']"
            pTooltip="create secret" label="New secret" class="ms-2"></button>
        </div>

        <span class="p-input-icon-left ml-auto">
          <i class="pi pi-search"></i>
          <input pInputText type="text" matchMode="contains"
            (input)="applyFilterApplicationRegistrationSecretsPN($event, 'contains')" placeholder="Search keyword" />
        </span>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="displayName">
          Display Name
          <p-sortIcon field="displayName"></p-sortIcon>
          <p-columnFilter type="text" field="displayName" display="menu"></p-columnFilter>
        </th>
        <th pSortableColumn="keyId">
          Secret Id
          <p-sortIcon field="keyId"></p-sortIcon>
          <p-columnFilter type="text" field="keyId" display="menu"></p-columnFilter>
        </th>
        <th pSortableColumn="hint"
          pTooltip="Client secrets cannot be viewed, except for immediatley after creation. Be sure to save the secret when created before leaving the dialog window.">
          Value
          <p-sortIcon field="hint"></p-sortIcon>
          <p-columnFilter type="text" field="hint" display="menu"></p-columnFilter>
        </th>
        <th pSortableColumn="endDateTime">
          Expires
          <p-sortIcon field="endDateTime"></p-sortIcon>
          <p-columnFilter type="text" field="endDateTime" display="menu"></p-columnFilter>
        </th>
        <th>
          Actions
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-appRegSecret>
      <tr class="p-selectable-row">
        <td pSortableColumn="displayName">
          {{ appRegSecret.displayName }}
        </td>
        <td pSortableColumn="keyId">
          {{ appRegSecret.keyId }}
        </td>
        <td pSortableColumn="hint">
          {{ appRegSecret.hint }}**************
        </td>
        <td pSortableColumn="endDateTime"
          [ngStyle]="{'color': appRegSecret.hasExpired ? 'red': appRegSecret.willExpireSoon ?  'orange' : 'green'}">
          {{ appRegSecret.endDateTime | date:'dd/MM/YYYY' }}
        </td>
        <td>
          <div class="form-group">
            <button class="p-button-secondary p-button-text" pButton pRipple (click)="onGenerateSecret(appRegSecret)"
              pTooltip="renew password">
              <i class="pi pi-refresh"></i>
            </button>
          </div>
          <div class="form-group">
            <button class="p-button-secondary p-button-text" pButton pRipple (click)="onDeleteSecret(appRegSecret)"
              pTooltip="delete">
              <i class="pi pi-trash"></i>
            </button>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="4">{{ noDataTitle }}</td>
      </tr>
    </ng-template>
  </p-table>
</div>

<br>

<div>
  <button pButton pRipple class="button p-button-raised back-button" [routerLink]="['/manage-app-reg']">
    <i class="pi pi-angle-left"></i> Back
  </button>
</div>
<p-confirmDialog header="Are you sure?" icon="pi pi-exclamation-triangle"></p-confirmDialog>
