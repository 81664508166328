import { formatDate } from '@angular/common';
import { Component} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Guid } from 'src/app/_shared/_classes/guid';

export interface AppSecretData {
  appRegName: string;
  appRegId: Guid;
  secretId: Guid;
  secretName: string;
  secret: string;
  endDate: string;
  startDate: string;
}

export class AppSecretsValueList {
  field: string;
  value: string;
}

@Component({
  templateUrl: './app-reg-secret-dialog.component.html',
  styleUrls: ['./app-reg-secret-dialog.component.scss']
})
export class AppRegSecretDialogComponent {
  dataSource: MatTableDataSource<AppSecretsValueList>;
  public appSecretsValueList: Array<AppSecretsValueList>;

  displayedColumns: string[] = ['field', 'value', 'star'];

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) {
    this.appSecretsValueList = AppRegSecretDialogComponent.generateResultList(config.data)
    this.dataSource = new MatTableDataSource(this.appSecretsValueList);
  }

  private static generateResultList(data: AppSecretData): AppSecretsValueList[] {
    var currentAppSecretsValueList = new Array<AppSecretsValueList>();

    var appRegName = new AppSecretsValueList();
    appRegName.field = 'App Name';
    appRegName.value = data.appRegName;

    currentAppSecretsValueList.push(appRegName);

    var appRegId = new AppSecretsValueList();
    appRegId.field = 'App Id';
    appRegId.value = data.appRegId as string;

    currentAppSecretsValueList.push(appRegId);

    var secretName = new AppSecretsValueList();
    secretName.field = 'Secret Name';
    secretName.value = data.secretName;

    currentAppSecretsValueList.push(secretName);

    var secretId = new AppSecretsValueList();
    secretId.field = 'Secret Id';
    secretId.value = data.secretId.toString();

    currentAppSecretsValueList.push(secretId);

    var secret = new AppSecretsValueList();
    secret.field = 'Secret';
    secret.value = data.secret;

    currentAppSecretsValueList.push(secret);

    var expires = new AppSecretsValueList();
    expires.field = 'Expires';
    expires.value = formatDate(data.endDate, 'dd/MM/YYYY', 'en-US');

    currentAppSecretsValueList.push(expires);

    return currentAppSecretsValueList;
  }

  close(){
    this.ref.close();
  }
}
