<h1 class="ml-4 mt-3">{{ 'create_landing_zone.landingZoneConfig' | translate }}</h1>
<ng-container *ngIf="(isHandset$ | async); then vertical; else horizontal">
</ng-container>

<ng-template #horizontal>
  <mat-horizontal-stepper (selectionChange)="onStepChange($event)" linear #stepper
    style="background-color: transparent;">
    <mat-step [stepControl]="basicForm" state="basic">
      <form [formGroup]="basicForm">
        <ng-template matStepLabel>
          <div>
            <span>{{ 'create_landing_zone.basics.title' | translate}}</span>
          </div>
        </ng-template>

        <div class="pt-4">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-6 pt-5">
              <span class="p-float-label">
                <input class="w-100" id="float-input" type="text" pInputText formControlName="appName"
                  (keypress)="keyPressAlpha($event)" (keyup)="onKeyUpApplicationName()" (focus)="onKeyUpApplicationName()" maxlength="8" required>
                <label for="float-input">{{ 'create_landing_zone.basics.app_name' | translate}}*</label>
              </span>
              <small id="username1-help" class="block">* Max 8 alpha characters</small>

              <small class="p-error"
                *ngIf="basicForm.get('appName')?.touched && basicForm.get('appName')?.errors?.required">{{
                'create_landing_zone.basics.errors.app_name.required' | translate}}
              </small>
              <small class="p-error" *ngIf="basicForm.get('appName')?.errors?.subscriptionNameExists">{{
                'create_landing_zone.basics.errors.subscription_name.exists' | translate}}
              </small>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 pt-5">

              <span class="p-float-label">
                <p-inputNumber id="appNumber" class="w-100" inputStyleClass="w-100" styleClass="w-100" [min]="1"
                  [max]="99" formControlName="appNumber" mode="decimal" inputId="withoutgrouping" [useGrouping]="false"
                  (onInput)="onChangeApplicationNumber()" (onBlur)="onChangeApplicationNumber()" [required]="true">
                </p-inputNumber>
                <label for="appNumber">{{ 'create_landing_zone.basics.app_number' | translate}}*</label>
              </span>
              <small class="p-error" *ngIf="basicForm.get('appNumber')?.errors?.required">{{
                'create_landing_zone.basics.errors.app_number.required' | translate}}</small>
              <small class="p-error" *ngIf="basicForm.get('appNumber')?.errors?.subscriptionNameExists">{{
                'create_landing_zone.basics.errors.subscription_name.exists' | translate}}
              </small>
            </div>
          </div>

          <div class="row">
            <div class="col-12 pt-4">
              <span class="field">
                <label for="appDescription">{{ 'create_landing_zone.basics.app_description' | translate}}</label>
                <textarea class="w-100" pInputTextarea formControlName="appDescription" id="appDescription"></textarea>
              </span>
            </div>
          </div>
          <div class="row pt-4">
            <div class="col-xs-12 col-sm-12 col-md-6 pt-5">


              <span class="p-float-label">
                <input class="w-100" id="float-input" type="text" pInputText formControlName="itServiceName" required>
                <label for="float-input">{{ 'create_landing_zone.basics.it_service_name' | translate}}*</label>
              </span>
              <small class="p-error"
                *ngIf="basicForm.get('itServiceName')?.touched && basicForm.get('itServiceName')?.invalid">{{
                'create_landing_zone.basics.errors.it_service_name.required' | translate}}</small>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 pt-5">
              <span class="p-float-label">
                <input class="w-100" id="float-input" type="text" pInputText formControlName="serviceId" required>
                <label for="float-input">{{ 'create_landing_zone.details.service_id' | translate}}*</label>
              </span>
              <small class="p-error" *ngIf="basicForm.get('serviceId')?.touched && basicForm.get('serviceId')!.invalid"
                id="username2-help">
                {{ 'You must enter a Service-Id.' }}
              </small>
            </div>
          </div>
          <div class="row pt-4">
            <div class="col-xs-12 col-sm-12 col-md-6 pt-5">
              <span class="p-float-label">
                <p-autoComplete appendTo="body" class="w-100" inputStyleClass="w-100" styleClass="w-100"
                  id="float-input" formControlName="itServiceOwner" [suggestions]="userSuggestions"
                  (completeMethod)="search($event)" [forceSelection]="true"
                  (onSelect)="onLookupSelected($event, 'itServiceOwner')">
                  <ng-template let-user pTemplate="item">
                    <div>
                      <div>{{user.displayName}}</div>
                      <small>{{user.email}}</small>
                    </div>
                  </ng-template>
                </p-autoComplete>

                <label for="float-input">{{ 'create_landing_zone.basics.it_service_owner' | translate}}*</label>
              </span>
              <small class="p-error"
                *ngIf="basicForm.get('itServiceOwner')?.touched && basicForm.get('itServiceOwner')?.errors?.email">{{
                'create_landing_zone.basics.errors.it_service_owner.required' | translate}}</small>
              <small class="p-error"
                *ngIf="basicForm.get('itServiceOwner')?.touched && basicForm.get('itServiceOwner')?.errors?.emailDoesNotExist">{{
                'create_landing_zone.basics.errors.it_service_owner.mail_does_not_exist' | translate}}
              </small>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 pt-5">

              <span class="p-float-label">
                <p-autoComplete appendTo="body" class="w-100" inputStyleClass="w-100" styleClass="w-100"
                  id="float-input" formControlName="itServiceOwnerDeputy" [suggestions]="userSuggestions"
                  (completeMethod)="search($event)" [forceSelection]="true"
                  (onSelect)="onLookupSelected($event, 'itServiceOwnerDeputy')">
                  <ng-template let-user pTemplate="item">
                    <div>
                      <div>{{user.displayName}}</div>
                      <small>{{user.email}}</small>
                    </div>
                  </ng-template>
                </p-autoComplete>

                <label for="float-input">{{ 'create_landing_zone.basics.it_service_owner_deputy' | translate}}*</label>
              </span>
              <small class="p-error" *ngIf="basicForm.get('itServiceOwnerDeputy')?.errors?.email">{{
                'create_landing_zone.basics.errors.it_service_owner_deputy.required' | translate}}
              </small>
              <small class="p-error" *ngIf="basicForm.get('itServiceOwnerDeputy')?.errors?.emailDoesNotExist">{{
                'create_landing_zone.basics.errors.it_service_owner_deputy.mail_does_not_exist' |
                translate}}</small>
            </div>
          </div>

          <div style="margin-top: 15px;">
            <button pButton pRipple class="p-button-raised" type="button"
              label="{{'create_landing_zone.basics.next' | translate}}" [disabled]="basicForm.invalid"
              matStepperNext></button>
          </div>
        </div>
      </form>
    </mat-step>

    <mat-step [stepControl]="detailsForm" state="details">
      <form [formGroup]="detailsForm">
        <ng-template matStepLabel>
          <div>
            <span>{{ 'create_landing_zone.details.title' | translate}}</span>
          </div>
        </ng-template>
        <div>
          <div class="row pt-4">
            <div class="col-xs-12 col-sm-12 col-md-6 pt-5">
              <label for="float-input">{{ 'create_landing_zone.details.managed_by' | translate}}*</label>
              <p-dropdown styleClass="w-100" class="w-100" [options]="managedByOptions" formControlName="managedBy"
                placeholder="{{ 'create_landing_zone.details.managed_by' | translate}}" optionLabel="name"
                [showClear]="false" optionValue="shortcut"></p-dropdown>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 pt-5">

              <label for="float-input">{{ 'create_landing_zone.basics.department' | translate}}*</label>
              <p-dropdown styleClass="w-100" class="w-100" [options]="departmentOptions" formControlName="department"
                placeholder="{{ 'create_landing_zone.basics.department' | translate}}" optionLabel="name"
                [showClear]="false" optionValue="shortcut"></p-dropdown>
              <small class="p-error" *ngIf="detailsForm.get('department')?.errors?.subscriptionNameExists">{{
                'create_landing_zone.basics.errors.subscription_name.exists' | translate}}
              </small>
            </div>
          </div>

          <div class="row pt-4">
            <div class="col-xs-12 col-sm-12 col-md-6 pt-5">
              <div class="field">
                <label for="float-input">{{ 'create_landing_zone.details.workload' | translate}}*</label>
                <p-dropdown styleClass="w-100" class="w-100" [options]="workloadOptions" formControlName="workload"
                  placeholder="{{ 'create_landing_zone.details.workload' | translate}}" optionLabel="name"
                  [showClear]="false" optionValue="shortcut"></p-dropdown>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 pt-5">

              <div class="field">
                <label for="annualBudget">{{ 'create_landing_zone.details.annual_budget' | translate}}*</label>
                <p-inputNumber id="annualBudget" class="w-100" inputStyleClass="w-100" styleClass="w-100"
                  formControlName="annualBudget" [useGrouping]="false" [required]="true"
                  placeholder="{{'100,00 &pound;'}}" suffix=" &pound;">
                </p-inputNumber>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 py-0">
              <h3 class="rrps--subheading" style="margin-bottom: 5px;">{{
                'create_landing_zone.details.allowed_resources' | translate}}
              </h3>

              <div style="max-height: 800px; overflow: auto;">
                <p-table #allowedResourcesTablePN [value]="dSAllowedResourcesPN" (onRowSelect)="onRowSelect($event)"
                  [(selection)]="selectedDSAllowedResourcesPN" dataKey="id" [rowHover]="true" [rows]="50"
                  [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]"
                  [loading]="dSAllowedResourcesLoadingPN" responsiveLayout="stack" [paginator]="true"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [filterDelay]="0"
                  [globalFilterFields]="['name','category']" [scrollable]="true" scrollHeight="519px"
                  styleClass="p-datatable-sm">
                  <ng-template pTemplate="caption">
                    <div class="flex" style="overflow: hidden;">
                      <button pButton class="p-button-outlined" icon="pi pi-filter-slash"
                        (click)="clear(allowedResourcesTablePN)"></button>
                      <span class="p-input-icon-left ml-auto">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" matchMode="contains" class="w-100" #ressourceFilter
                          (input)="applyFilterAllowedResourcesPN($event, 'contains')" placeholder="Search keyword" />
                      </span>
                    </div>
                  </ng-template>
                  <ng-template pTemplate="header">
                    <tr>
                      <th class="checkbox-column">
                        <div class="flex justify-content-left align-items-left">
                          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </div>
                      </th>
                      <th pSortableColumn="name">
                        Name
                        <p-sortIcon field="name"></p-sortIcon>
                        <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
                      </th>
                      <th pSortableColumn="category">
                        Category
                        <p-sortIcon field="category"></p-sortIcon>
                        <p-columnFilter type="text" field="category" display="menu">
                        </p-columnFilter>
                      </th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-allowedResource>
                    <tr class="p-selectable-row">
                      <td class="checkbox-column">
                        <p-tableCheckbox [value]="allowedResource" [disabled]="isDependency(allowedResource)">
                        </p-tableCheckbox>
                      </td>
                      <td>
                        {{allowedResource.name}}
                      </td>
                      <td>
                        {{allowedResource.category}}
                      </td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage">
                    <tr>
                      <td colspan="3">No allowed resource found.</td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 py-0">
              <div style="margin-top: 15px;">
                <button pButton pRipple class="p-button-raised" style="margin-right: 10px;" matStepperPrevious>{{
                  'create_landing_zone.details.back' | translate}}</button>
                <button pButton pRipple class="p-button-raised" [disabled]="detailsForm.invalid" matStepperNext>{{
                  'create_landing_zone.details.next' | translate}}</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </mat-step>

    <mat-step [stepControl]="permissionsForm" state="permissions">
      <form [formGroup]="permissionsForm">
        <ng-template matStepLabel>
          <div>
            <span>{{ 'create_landing_zone.permissions.title' | translate}}</span>
          </div>
        </ng-template>
        <div>
          <div class="row pt-4" *ngIf="_allStages.length > 0">
            <div class="col-12">
              <h2>Permissions on Subscription Level:</h2>
              <div class="p-3 border">
                <app-stage-permissions [form]="permissionsForm" [stageName]="'SUBSCRIPTION'"></app-stage-permissions>
              </div>
            </div>

            <div class="col-12">
              <h2>Permissions on Resource Group Level:</h2>
              <div class="p-3 border">
                <p-accordion [multiple]="true">
                  <p-accordionTab *ngFor="let stage of _allStages" header="{{stage.shortcut + ' Stage'}}">
                    <app-stage-permissions [form]="permissionsForm" [stageName]="stage.shortcut">
                    </app-stage-permissions>
                  </p-accordionTab>
                </p-accordion>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 py-0">
              <div style="margin-top: 15px;">
                <button pButton pRipple class="p-button-raised" style="margin-right: 10px;" matStepperPrevious>{{
                  'create_landing_zone.details.back' | translate}}</button>
                <button pButton pRipple class="p-button-raised" [disabled]="detailsForm.invalid" matStepperNext>{{
                  'create_landing_zone.details.next' | translate}}</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </mat-step>

    <mat-step state="review">
      <ng-template matStepLabel>
        <div>
          <span>{{ 'create_landing_zone.review_create.title' | translate}}</span>
        </div>
      </ng-template>

      <div>
        <div class="row">
          <div class="col-12 py-0">
            <h3 class="rrps--subheading" style="margin-bottom: 5px;">{{
              'create_landing_zone.review_create.summary' | translate}}</h3>
            <p>
              {{ 'create_landing_zone.review_create.landing_zone_deployment' | translate}}
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 py-0">
            <p-accordion>
              <p-accordionTab header="{{ 'create_landing_zone.basics.title' | translate}}">
                <div class="listItem">
                  <b>{{ 'create_landing_zone.basics.app_name' | translate }}</b>
                  <div>{{ basicForm.get('appName')!.value }}</div>
                </div>

                <div class="listItem">
                  <b>{{ 'create_landing_zone.basics.app_number' | translate }}</b>
                  <div>{{ basicForm.get('appNumber')!.value }}</div>
                </div>

                <div class="listItem">
                  <b>{{ 'create_landing_zone.basics.app_description' | translate }}</b>
                  <div>
                    {{ basicForm.get('appDescription')!.value }}
                  </div>
                </div>

                <div class="listItem">
                  <b>{{ 'create_landing_zone.basics.it_service_name' | translate }}</b>
                  <div>{{ basicForm.get('itServiceName')!.value }}</div>
                </div>

                <div class="listItem">
                  <b>{{ 'create_landing_zone.details.service_id' | translate }}</b>
                  <div>{{ basicForm.get('serviceId')!.value }}</div>
                </div>

                <div class="listItem">
                  <b>{{ 'create_landing_zone.basics.it_service_owner' | translate }}</b>
                  <div>{{ basicForm.get('itServiceOwner')!.value }}</div>
                </div>

                <div class="listItem">
                  <b>{{ 'create_landing_zone.basics.it_service_owner_deputy' | translate }}</b>
                  <div>{{ basicForm.get('itServiceOwnerDeputy')!.value }}</div>
                </div>
              </p-accordionTab>
              <p-accordionTab header="{{ 'create_landing_zone.review_create.resource_groups.title' | translate}}">
                <p-table [value]="defaultResourceGroups" dataKey="name" [rows]="50" [showCurrentPageReport]="true"
                  [rowsPerPageOptions]="[10,25,50]" responsiveLayout="scroll" [paginator]="false" [scrollable]="true"
                  scrollHeight="flex" styleClass="p-datatable-sm">
                  <ng-template pTemplate="header">
                    <tr>
                      <th>
                        Name
                      </th>
                      <th>
                        Location
                      </th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-resourceGroup>
                    <tr class="p-selectable-row">
                      <td>
                        {{resourceGroup.name}}
                      </td>
                      <td>
                        {{resourceGroup.location}}
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </p-accordionTab>
              <p-accordionTab header="{{ 'create_landing_zone.review_create.aad_groups.title' | translate}}">


                <p-table [value]="defaultAadGroups" dataKey="name" [rows]="50" [showCurrentPageReport]="true"
                  [rowsPerPageOptions]="[10,25,50]" responsiveLayout="scroll" [paginator]="false" [scrollable]="true"
                  scrollHeight="flex" styleClass="p-datatable-sm">
                  <ng-template pTemplate="header">
                    <tr>
                      <th>
                        {{ 'create_landing_zone.review_create.aad_groups.name' | translate}}
                      </th>
                      <th>
                        {{ 'create_landing_zone.review_create.aad_groups.scope' | translate}}
                      </th>
                      <th>
                        {{ 'create_landing_zone.review_create.aad_groups.description' | translate}}
                      </th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-element>
                    <tr class="p-selectable-row">
                      <td>
                        {{ element.name }}
                      </td>
                      <td>
                        {{ (element.scope == 0 ? 'create_landing_zone.review_create.aad_groups.subscription' :
                        'create_landing_zone.review_create.aad_groups.resource_group') | translate}}
                      </td>
                      <td>
                        {{ element.description.split(' ')[1] }}
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </p-accordionTab>
              <p-accordionTab header=" {{ 'create_landing_zone.review_create.selected_services' | translate}}">
                <p-table #allowedResourcesTablePN [value]="selectedDSAllowedResourcesPN" dataKey="name"
                  [rowHover]="true" [rows]="50" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]"
                  [loading]="dSAllowedResourcesLoadingPN" responsiveLayout="scroll" [paginator]="true"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [filterDelay]="0"
                  [globalFilterFields]="['name','category']" [scrollable]="true" scrollHeight="519px"
                  styleClass="p-datatable-sm">
                  <ng-template pTemplate="header">
                    <tr>
                      <th pSortableColumn="name">
                        Name
                        <p-sortIcon field="name"></p-sortIcon>
                        <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
                      </th>
                      <th pSortableColumn="category">
                        Category
                        <p-sortIcon field="category"></p-sortIcon>
                        <p-columnFilter type="text" field="category" display="menu">
                        </p-columnFilter>
                      </th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-allowedResource>
                    <tr class="p-selectable-row">
                      <td>
                        {{allowedResource.name}}
                      </td>
                      <td>
                        {{allowedResource.category}}
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </p-accordionTab>
              <p-accordionTab header="{{ 'create_landing_zone.review_create.permissions' | translate}}">
                <span *ngIf="_allStages.length > 0">
                  <div class="listItem row">
                    <div class="col-4">
                      <b>SUBSCRIPTION Reader</b>
                    </div>
                    <div class="col-8">{{ getPermissionValues('SUBSCRIPTION_Reader')}}</div>
                  </div>
                  <div class="listItem row">
                    <div class="col-4">
                      <b>SUBSCRIPTION Contributor</b>
                    </div>
                    <div class="col-8">{{ getPermissionValues('SUBSCRIPTION_Contributor') }}</div>
                  </div>
                  <div *ngFor="let stage of _allStages">
                    <div class="listItem row">
                      <div class="col-4">
                        <b>{{ 'create_landing_zone.review_create.resource_groups.resource_group' | translate}} {{
                          stage.shortcut + ' Reader' }}</b>
                      </div>
                      <div class="col-8">{{ getPermissionValues(stage.shortcut + '_Reader')}}</div>
                    </div>
                    <div class="listItem row">
                      <div class="col-4">
                        <b>{{ 'create_landing_zone.review_create.resource_groups.resource_group' | translate}} {{
                          stage.shortcut + ' Contributor' }}</b>
                      </div>
                      <div class="col-8">{{ getPermissionValues(stage.shortcut + '_Contributor') }}</div>
                    </div>
                  </div>
                </span>
              </p-accordionTab>
              <p-accordionTab header="{{ 'create_landing_zone.review_create.technical' | translate}}">
                <button pButton pRipple class="p-button-raised" color="primary"
                  [cdkCopyToClipboard]="getRequestJsonStr()" (click)="openRequestJsonWasCopied()">{{
                  'create_landing_zone.review_create.copy_request' |
                  translate}}</button>
                <br>
                <pre>{{ requestJson | json }}</pre>
              </p-accordionTab>
            </p-accordion>
          </div>
        </div>
        <div class="row">
          <div class="col-12 py-0">
            <div style="margin-top: 15px;">
              <button pButton pRipple class="p-button-raised" style="margin-right: 10px;" matStepperPrevious>{{
                'create_landing_zone.review_create.back' | translate}}</button>
              <button pButton pRipple class="p-button-raised" (click)="createLandingZone()"
                [disabled]="requestJsonWasSuccessfullyCommitted || (!requestJsonWasSuccessfullyCommittedFirstTry && !requestJsonWasSuccessfullyCommitted)"
                style="margin-right: 10px;">{{ 'create_landing_zone.review_create.create' | translate}}</button>
              <button pButton pRipple class="p-button-raised" (click)="commitRequestJsonOnly()"
                *ngIf="!requestJsonWasSuccessfullyCommittedFirstTry && !requestJsonWasSuccessfullyCommitted"
                style="margin-right: 10px;">Upload Again</button>
              <button pButton pRipple class="p-button-raised" (click)="refreshPage()"
                *ngIf="requestJsonWasSuccessfullyCommitted">{{ 'create_landing_zone.review_create.refresh' |
                translate}}</button>
            </div>
          </div>
        </div>
      </div>
    </mat-step>

    <!-- Icon overrides. -->
    <ng-template matStepperIcon="basic">
      <mat-icon>home</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="details">
      <mat-icon>settings</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="review">
      <mat-icon>build</mat-icon>
    </ng-template>
  </mat-horizontal-stepper>
</ng-template>

<ng-template #vertical>
  <mat-vertical-stepper (selectionChange)="onStepChange($event)" linear #stepper style="background-color: transparent;">
    <mat-step [stepControl]="basicForm" state="basic">
      <form [formGroup]="basicForm">
        <ng-template matStepLabel>
          <div>
            <span>{{ 'create_landing_zone.basics.title' | translate}}</span>
          </div>
        </ng-template>

        <div class="pt-4">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-6 pt-5">
              <span class="p-float-label">
                <input class="w-100" id="float-input" type="text" pInputText formControlName="appName"
                  (keypress)="keyPressAlpha($event)" (keyup)="onKeyUpApplicationName()" maxlength="8" required>
                <label for="float-input">{{ 'create_landing_zone.basics.app_name' | translate}}*</label>
              </span>
              <small id="username1-help" class="block">* Max 8 alpha characters</small>

              <small class="p-error"
                *ngIf="basicForm.get('appName')?.touched && basicForm.get('appName')?.errors?.required">{{
                'create_landing_zone.basics.errors.app_name.required' | translate}}
              </small>
              <small class="p-error" *ngIf="basicForm.get('appName')?.errors?.subscriptionNameExists">{{
                'create_landing_zone.basics.errors.subscription_name.exists' | translate}}
              </small>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 pt-5">

              <span class="p-float-label">
                <p-inputNumber id="appNumber" class="w-100" inputStyleClass="w-100" styleClass="w-100" [min]="1"
                  [max]="99" formControlName="appNumber" mode="decimal" inputId="withoutgrouping" [useGrouping]="false"
                  (onInput)="onChangeApplicationNumber()" (onBlur)="onChangeApplicationNumber()" [required]="true">
                </p-inputNumber>
                <label for="appNumber">{{ 'create_landing_zone.basics.app_number' | translate}}*</label>
              </span>
              <small class="p-error" *ngIf="basicForm.get('appNumber')?.errors?.required">{{
                'create_landing_zone.basics.errors.app_number.required' | translate}}</small>
              <small class="p-error" *ngIf="basicForm.get('appNumber')?.errors?.subscriptionNameExists">{{
                'create_landing_zone.basics.errors.subscription_name.exists' | translate}}
              </small>
            </div>
          </div>

          <div class="row">
            <div class="col-12 pt-4">
              <span class="field">
                <label for="appDescription">{{ 'create_landing_zone.basics.app_description' | translate}}</label>
                <textarea class="w-100" pInputTextarea formControlName="appDescription" id="appDescription"></textarea>
              </span>
            </div>
          </div>
          <div class="row pt-4">
            <div class="col-xs-12 col-sm-12 col-md-6 pt-5">


              <span class="p-float-label">
                <input class="w-100" id="float-input" type="text" pInputText formControlName="itServiceName" required>
                <label for="float-input">{{ 'create_landing_zone.basics.it_service_name' | translate}}*</label>
              </span>
              <small class="p-error"
                *ngIf="basicForm.get('itServiceName')?.touched && basicForm.get('itServiceName')?.invalid">{{
                'create_landing_zone.basics.errors.it_service_name.required' | translate}}</small>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 pt-5">
              <span class="p-float-label">
                <input class="w-100" id="float-input" type="text" pInputText formControlName="serviceId" required>
                <label for="float-input">{{ 'create_landing_zone.details.service_id' | translate}}*</label>
              </span>
              <small class="p-error" *ngIf="basicForm.get('serviceId')?.touched && basicForm.get('serviceId')!.invalid"
                id="username2-help">
                {{ 'You must enter a Service-Id.' }}
              </small>
            </div>
          </div>
          <div class="row pt-4">
            <div class="col-xs-12 col-sm-12 col-md-6 pt-5">
              <span class="p-float-label">
                <p-autoComplete appendTo="body" class="w-100" inputStyleClass="w-100" styleClass="w-100"
                  id="float-input" formControlName="itServiceOwner" [suggestions]="userSuggestions"
                  (completeMethod)="search($event)" [forceSelection]="true"
                  (onSelect)="onLookupSelected($event, 'itServiceOwner')">
                  <ng-template let-user pTemplate="item">
                    <div>
                      <div>{{user.displayName}}</div>
                      <small>{{user.email}}</small>
                    </div>
                  </ng-template>
                </p-autoComplete>
                <label for="float-input">{{ 'create_landing_zone.basics.it_service_owner' | translate}}*</label>
              </span>
              <small class="p-error"
                *ngIf="basicForm.get('itServiceOwner')?.touched && basicForm.get('itServiceOwner')?.errors?.email">{{
                'create_landing_zone.basics.errors.it_service_owner.required' | translate}}</small>
              <small class="p-error"
                *ngIf="basicForm.get('itServiceOwner')?.touched && basicForm.get('itServiceOwner')?.errors?.emailDoesNotExist">{{
                'create_landing_zone.basics.errors.it_service_owner.mail_does_not_exist' | translate}}
              </small>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 pt-5">

              <span class="p-float-label">
                <p-autoComplete appendTo="body" class="w-100" inputStyleClass="w-100" styleClass="w-100"
                  id="float-input" formControlName="itServiceOwnerDeputy" [suggestions]="userSuggestions"
                  (completeMethod)="search($event)" [forceSelection]="true"
                  (onSelect)="onLookupSelected($event, 'itServiceOwnerDeputy')">
                  <ng-template let-user pTemplate="item">
                    <div>
                      <div>{{user.displayName}}</div>
                      <small>{{user.email}}</small>
                    </div>
                  </ng-template>
                </p-autoComplete>
                <label for="float-input">{{ 'create_landing_zone.basics.it_service_owner_deputy' | translate}}*</label>
              </span>
              <small class="p-error" *ngIf="basicForm.get('itServiceOwnerDeputy')?.errors?.email">{{
                'create_landing_zone.basics.errors.it_service_owner_deputy.required' | translate}}
              </small>
              <small class="p-error" *ngIf="basicForm.get('itServiceOwnerDeputy')?.errors?.emailDoesNotExist">{{
                'create_landing_zone.basics.errors.it_service_owner_deputy.mail_does_not_exist' |
                translate}}</small>
            </div>
          </div>

          <div style="margin-top: 15px;">
            <button pButton pRipple class="p-button-raised" type="button"
              label="{{'create_landing_zone.basics.next' | translate}}" [disabled]="basicForm.invalid"
              matStepperNext></button>
          </div>
        </div>
      </form>
    </mat-step>

    <mat-step [stepControl]="detailsForm" state="details">
      <form [formGroup]="detailsForm">
        <ng-template matStepLabel>
          <div>
            <span>{{ 'create_landing_zone.details.title' | translate}}</span>
          </div>
        </ng-template>
        <div>
          <div class="row pt-4">
            <div class="col-xs-12 col-sm-12 col-md-6 pt-5">
              <label for="float-input">{{ 'create_landing_zone.details.managed_by' | translate}}*</label>
              <p-dropdown styleClass="w-100" class="w-100" [options]="managedByOptions" formControlName="managedBy"
                placeholder="{{ 'create_landing_zone.details.managed_by' | translate}}" optionLabel="name"
                [showClear]="false" optionValue="shortcut"></p-dropdown>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 pt-5">

              <label for="float-input">{{ 'create_landing_zone.basics.department' | translate}}*</label>
              <p-dropdown styleClass="w-100" class="w-100" [options]="departmentOptions" formControlName="department"
                placeholder="{{ 'create_landing_zone.basics.department' | translate}}" optionLabel="name"
                [showClear]="false" optionValue="shortcut"></p-dropdown>
              <small class="p-error" *ngIf="detailsForm.get('department')?.errors?.subscriptionNameExists">{{
                'create_landing_zone.basics.errors.subscription_name.exists' | translate}}
              </small>
            </div>
          </div>

          <div class="row pt-4">
            <div class="col-xs-12 col-sm-12 col-md-6 pt-5">
              <div class="field">
                <label for="float-input">{{ 'create_landing_zone.details.workload' | translate}}*</label>
                <p-dropdown styleClass="w-100" class="w-100" [options]="workloadOptions" formControlName="workload"
                  placeholder="{{ 'create_landing_zone.details.workload' | translate}}" optionLabel="name"
                  [showClear]="false" optionValue="shortcut"></p-dropdown>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 pt-5">

              <div class="field">
                <label for="annualBudget">{{ 'create_landing_zone.details.annual_budget' | translate}}*</label>
                <p-inputNumber id="annualBudget" class="w-100" inputStyleClass="w-100" styleClass="w-100"
                  formControlName="annualBudget" [useGrouping]="false" [required]="true"
                  placeholder="{{'100,00 &pound;'}}" suffix=" &pound;">
                </p-inputNumber>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 py-0">
              <h3 class="rrps--subheading" style="margin-bottom: 5px;">{{
                'create_landing_zone.details.allowed_resources' | translate}}
              </h3>

              <div style="max-height: 800px; overflow: auto;">
                <p-table #allowedResourcesTablePN [value]="dSAllowedResourcesPN" (onRowSelect)="onRowSelect($event)"
                  [(selection)]="selectedDSAllowedResourcesPN" dataKey="id" [rowHover]="true" [rows]="50"
                  [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]"
                  [loading]="dSAllowedResourcesLoadingPN" responsiveLayout="stack" [paginator]="true"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [filterDelay]="0"
                  [globalFilterFields]="['name','category']" [scrollable]="true" scrollHeight="519px"
                  styleClass="p-datatable-sm">
                  <ng-template pTemplate="caption">
                    <div class="flex">
                      <button pButton label="Clear" class="p-button-outlined" icon="pi pi-filter-slash"
                        (click)="clear(allowedResourcesTablePN)" style="min-width: 6rem;"></button>
                      <span class="p-input-icon-left ml-auto">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" matchMode="contains" class="w-100" #ressourceFilter
                          (input)="applyFilterAllowedResourcesPN($event, 'contains')" placeholder="Search keyword" />
                      </span>
                    </div>
                  </ng-template>
                  <ng-template pTemplate="header">
                    <tr>
                      <th class="checkbox-column">
                        <div class="flex justify-content-left align-items-left">
                          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </div>
                      </th>
                      <th pSortableColumn="name">
                        Name
                        <p-sortIcon field="name"></p-sortIcon>
                        <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
                      </th>
                      <th pSortableColumn="category">
                        Category
                        <p-sortIcon field="category"></p-sortIcon>
                        <p-columnFilter type="text" field="category" display="menu">
                        </p-columnFilter>
                      </th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-allowedResource>
                    <tr class="p-selectable-row">
                      <td class="checkbox-column">
                        <p-tableCheckbox [value]="allowedResource" [disabled]="isDependency(allowedResource)">
                        </p-tableCheckbox>
                      </td>
                      <td>
                        {{allowedResource.name}}
                      </td>
                      <td>
                        {{allowedResource.category}}
                      </td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage">
                    <tr>
                      <td colspan="3">No allowed resource found.</td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 py-0">
              <div style="margin-top: 15px;">
                <button pButton pRipple class="p-button-raised" style="margin-right: 10px;" matStepperPrevious>{{
                  'create_landing_zone.details.back' | translate}}</button>
                <button pButton pRipple class="p-button-raised" [disabled]="detailsForm.invalid" matStepperNext>{{
                  'create_landing_zone.details.next' | translate}}</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </mat-step>

    <mat-step [stepControl]="permissionsForm" state="permissions">
      <form [formGroup]="permissionsForm">
        <ng-template matStepLabel>
          <div>
            <span>{{ 'create_landing_zone.permissions.title' | translate}}</span>
          </div>
        </ng-template>
        <div>
          <div class="row pt-4" *ngIf="_allStages.length > 0">
            <div class="col-12">
              <h2>Permissions on Subscription Level:</h2>
              <div class="p-3 border">
                <app-stage-permissions [form]="permissionsForm" [stageName]="'SUBSCRIPTION'"></app-stage-permissions>
              </div>
            </div>

            <div class="col-12">
              <h2>Permissions on Resource Group Level:</h2>
              <div class="p-3 border">
                <p-accordion [multiple]="true">
                  <p-accordionTab *ngFor="let stage of _allStages" header="{{stage.shortcut + ' Stage'}}">
                    <app-stage-permissions [form]="permissionsForm" [stageName]="stage.shortcut">
                    </app-stage-permissions>
                  </p-accordionTab>
                </p-accordion>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 py-0">
              <div style="margin-top: 15px;">
                <button pButton pRipple class="p-button-raised" style="margin-right: 10px;" matStepperPrevious>{{
                  'create_landing_zone.details.back' | translate}}</button>
                <button pButton pRipple class="p-button-raised" [disabled]="detailsForm.invalid" matStepperNext>{{
                  'create_landing_zone.details.next' | translate}}</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </mat-step>

    <mat-step state="review">
      <ng-template matStepLabel>
        <div>
          <span>{{ 'create_landing_zone.review_create.title' | translate}}</span>
        </div>
      </ng-template>

      <div>
        <div class="row">
          <div class="col-12 py-0">
            <h3 class="rrps--subheading" style="margin-bottom: 5px;">{{
              'create_landing_zone.review_create.summary' | translate}}</h3>
            <p>
              {{ 'create_landing_zone.review_create.landing_zone_deployment' | translate}}
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 py-0">
            <p-accordion>
              <p-accordionTab header="{{ 'create_landing_zone.basics.title' | translate}}">
                <div class="listItem">
                  <b>{{ 'create_landing_zone.basics.app_name' | translate }}</b>
                  <div>{{ basicForm.get('appName')!.value }}</div>
                </div>

                <div class="listItem">
                  <b>{{ 'create_landing_zone.basics.app_number' | translate }}</b>
                  <div>{{ basicForm.get('appNumber')!.value }}</div>
                </div>

                <div class="listItem">
                  <b>{{ 'create_landing_zone.basics.app_description' | translate }}</b>
                  <div>
                    {{ basicForm.get('appDescription')!.value }}
                  </div>
                </div>

                <div class="listItem">
                  <b>{{ 'create_landing_zone.basics.it_service_name' | translate }}</b>
                  <div>{{ basicForm.get('itServiceName')!.value }}</div>
                </div>

                <div class="listItem">
                  <b>{{ 'create_landing_zone.details.service_id' | translate }}</b>
                  <div>{{ basicForm.get('serviceId')!.value }}</div>
                </div>

                <div class="listItem">
                  <b>{{ 'create_landing_zone.basics.it_service_owner' | translate }}</b>
                  <div>{{ basicForm.get('itServiceOwner')!.value }}</div>
                </div>

                <div class="listItem">
                  <b>{{ 'create_landing_zone.basics.it_service_owner_deputy' | translate }}</b>
                  <div>{{ basicForm.get('itServiceOwnerDeputy')!.value }}</div>
                </div>
              </p-accordionTab>
              <p-accordionTab header="{{ 'create_landing_zone.review_create.resource_groups.title' | translate}}">
                <p-table [value]="defaultResourceGroups" dataKey="name" [rows]="50" [showCurrentPageReport]="true"
                  [rowsPerPageOptions]="[10,25,50]" responsiveLayout="scroll" [paginator]="false" [scrollable]="true"
                  scrollHeight="flex" styleClass="p-datatable-sm">
                  <ng-template pTemplate="header">
                    <tr>
                      <th>
                        Name
                      </th>
                      <th>
                        Location
                      </th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-resourceGroup>
                    <tr class="p-selectable-row">
                      <td>
                        {{resourceGroup.name}}
                      </td>
                      <td>
                        {{resourceGroup.location}}
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </p-accordionTab>
              <p-accordionTab header="{{ 'create_landing_zone.review_create.aad_groups.title' | translate}}">


                <p-table [value]="defaultAadGroups" dataKey="name" [rows]="50" [showCurrentPageReport]="true"
                  [rowsPerPageOptions]="[10,25,50]" responsiveLayout="scroll" [paginator]="false" [scrollable]="true"
                  scrollHeight="flex" styleClass="p-datatable-sm">
                  <ng-template pTemplate="header">
                    <tr>
                      <th>
                        {{ 'create_landing_zone.review_create.aad_groups.name' | translate}}
                      </th>
                      <th>
                        {{ 'create_landing_zone.review_create.aad_groups.scope' | translate}}
                      </th>
                      <th>
                        {{ 'create_landing_zone.review_create.aad_groups.description' | translate}}
                      </th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-element>
                    <tr class="p-selectable-row">
                      <td>
                        {{ element.name }}
                      </td>
                      <td>
                        {{ (element.scope == 0 ? 'create_landing_zone.review_create.aad_groups.subscription' :
                        'create_landing_zone.review_create.aad_groups.resource_group') | translate}}
                      </td>
                      <td>
                        {{ element.description.split(' ')[1] }}
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </p-accordionTab>
              <p-accordionTab header=" {{ 'create_landing_zone.review_create.selected_services' | translate}}">
                <p-table #allowedResourcesTablePN [value]="selectedDSAllowedResourcesPN" dataKey="name"
                  [rowHover]="true" [rows]="50" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]"
                  [loading]="dSAllowedResourcesLoadingPN" responsiveLayout="scroll" [paginator]="true"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [filterDelay]="0"
                  [globalFilterFields]="['name','category']" [scrollable]="true" scrollHeight="519px"
                  styleClass="p-datatable-sm">
                  <ng-template pTemplate="header">
                    <tr>
                      <th pSortableColumn="name">
                        Name
                        <p-sortIcon field="name"></p-sortIcon>
                        <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
                      </th>
                      <th pSortableColumn="category">
                        Category
                        <p-sortIcon field="category"></p-sortIcon>
                        <p-columnFilter type="text" field="category" display="menu">
                        </p-columnFilter>
                      </th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-allowedResource>
                    <tr class="p-selectable-row">
                      <td>
                        {{allowedResource.name}}
                      </td>
                      <td>
                        {{allowedResource.category}}
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </p-accordionTab>
              <p-accordionTab header="{{ 'create_landing_zone.review_create.permissions' | translate}}">
                <span *ngIf="_allStages.length > 0">
                  <div class="listItem row">
                    <div class="col-4">
                      <b>{{ 'SUBSCRIPTION Reader' | translate }}</b>
                    </div>
                    <div class="col-8">{{ getPermissionValues('SUBSCRIPTION_Reader')}}</div>
                  </div>
                  <div class="listItem row">
                    <div class="col-4">
                      <b>{{ 'SUBSCRIPTION Contributor' | translate }}</b>
                    </div>
                    <div class="col-8">{{ getPermissionValues('SUBSCRIPTION_Contributor') }}</div>
                  </div>
                  <div *ngFor="let stage of _allStages">
                    <div class="listItem row">
                      <div class="col-4">
                        <b>{{ stage.shortcut + ' Reader' | translate }}</b>
                      </div>
                      <div class="col-8">{{ getPermissionValues(stage.shortcut + '_Reader')}}</div>
                    </div>
                    <div class="listItem row">
                      <div class="col-4">
                        <b>{{ stage.shortcut + ' Contributor' | translate }}</b>
                      </div>
                      <div class="col-8">{{ getPermissionValues(stage.shortcut + '_Contributor') }}</div>
                    </div>
                  </div>
                </span>
              </p-accordionTab>
              <p-accordionTab header="{{ 'create_landing_zone.review_create.technical' | translate}}">
                <button pButton pRipple class="p-button-raised" color="primary"
                  [cdkCopyToClipboard]="getRequestJsonStr()" (click)="openRequestJsonWasCopied()">{{
                  'create_landing_zone.review_create.copy_request' |
                  translate}}</button>
                <br>
                <pre>{{ requestJson | json }}</pre>
              </p-accordionTab>
            </p-accordion>
          </div>
        </div>
        <div class="row">
          <div class="col-12 py-0">
            <div style="margin-top: 15px;">
              <button pButton pRipple class="p-button-raised" style="margin-right: 10px;" matStepperPrevious>{{
                'create_landing_zone.review_create.back' | translate}}</button>
              <button pButton pRipple class="p-button-raised" (click)="createLandingZone()"
                [disabled]="requestJsonWasSuccessfullyCommitted || (!requestJsonWasSuccessfullyCommittedFirstTry && !requestJsonWasSuccessfullyCommitted)"
                style="margin-right: 10px;">{{ 'create_landing_zone.review_create.create' | translate}}</button>
              <button pButton pRipple class="p-button-raised" (click)="commitRequestJsonOnly()"
                *ngIf="!requestJsonWasSuccessfullyCommittedFirstTry && !requestJsonWasSuccessfullyCommitted"
                style="margin-right: 10px;">Upload Again</button>
              <button pButton pRipple class="p-button-raised" (click)="refreshPage()"
                *ngIf="requestJsonWasSuccessfullyCommitted">{{ 'create_landing_zone.review_create.refresh' |
                translate}}</button>
            </div>
          </div>
        </div>
      </div>
    </mat-step>

    <!-- Icon overrides. -->
    <ng-template matStepperIcon="basic">
      <mat-icon>home</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="details">
      <mat-icon>settings</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="review">
      <mat-icon>build</mat-icon>
    </ng-template>
  </mat-vertical-stepper>
</ng-template>
