import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

export interface ErrorDialogData {
  title: string;
  message: string;
  optionOne?: {
    value: string;
    display: string;
  }
}

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent implements OnInit {

  constructor(
    public config: DynamicDialogConfig
  ) {

  }

  ngOnInit(): void {
  }

}
