import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';

import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';

import { SidebarModule } from 'primeng/sidebar';
import { StepsModule } from 'primeng/steps';

import { InputNumberModule } from 'primeng/inputnumber';
import { DropdownModule } from 'primeng/dropdown';
import { AccordionModule } from 'primeng/accordion';
import { TooltipModule } from 'primeng/tooltip';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { AutoCompleteModule } from 'primeng/autocomplete';


const PrimeNgComponents = [
  TableModule,
  ButtonModule,
  InputTextModule,
  ConfirmDialogModule,
  SidebarModule,
  StepsModule,
  InputNumberModule,
  DropdownModule,
  AccordionModule,
  TooltipModule,
  DropdownModule,
  DynamicDialogModule,
  AutoCompleteModule
];

@NgModule({
  imports: [CommonModule, PrimeNgComponents],
  exports: [PrimeNgComponents],
  providers: [ConfirmationService, DialogService]
})

export class PrimeNgModule { }
