import { mainRoutesNames } from './main.routes.names';
import { MainComponent } from './main.component';

import { CREATE_LANDING_ZONE_ROUTES } from './create-landing-zone/create-landing-zone.routes';

import { ABOUT_ROUTES } from './about/about.routes';
import { MANAGE_APP_REG_ROUTES } from './manage-app-reg/manage-app-reg.routes';
import { HOME_ROUTES } from './home/home.routes';
import { MsalGuard } from '@azure/msal-angular';
import { RoleGuardService } from './role-guard-service';

import auth from './auth-config.json';

export const MAIN_ROUTES = [
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: mainRoutesNames.HOME,
        children: HOME_ROUTES
      },
      {
        path: mainRoutesNames.CREATE_LANDING_ZONE,
        children: CREATE_LANDING_ZONE_ROUTES,
        canActivate: [
          MsalGuard,
          RoleGuardService
        ],
        data: {
          expectedRole: auth.roles.LandingZoneUser,
          expectedTenants: auth.Tenants
        }
      },
      {
        path: mainRoutesNames.MANAGE_APP_REG,
        children: MANAGE_APP_REG_ROUTES,
        canActivate: [
          MsalGuard,
          RoleGuardService
        ],
        data: {
          expectedRole: auth.roles.AppRegistrationUser,
          expectedTenants: auth.Tenants
        }
      },
      {
        path: mainRoutesNames.ABOUT,
        children: ABOUT_ROUTES
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: mainRoutesNames.HOME
      }
    ]
  }
];
