import { AboutComponent } from './about.component';
import {RoleGuardService} from "../role-guard-service";
import auth from "../auth-config.json";

export const ABOUT_ROUTES = [
    {
      path: '',
      component: AboutComponent,
      canActivate: [RoleGuardService],
      data: {
        expectedTenants: auth.Tenants
      }
    }
];
