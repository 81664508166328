import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppRegPasswordCredentials } from '../_classes/app-reg-password-credentials';
import { AppRegistration } from '../_classes/app-registration';
import { AutocompleteUser } from '../_classes/autocompleteUser';
import { Build, BuildResponse } from '../_classes/build';
import { Guid } from '../_classes/guid';
import { PasswordCredential } from '../_classes/password-credential';
import { SubscriptionResponse } from '../_classes/subscription';
import { Pending } from '../_interfaces/pending';
import { ApiService } from './api/api.service';

@Injectable({
  providedIn: 'root'
})
export class LandingZoneApiService {

  public constructor(
    private _apiService: ApiService
  ) { }

  public fetchSubscriptions(): Pending<SubscriptionResponse> {
    return this._apiService.get<SubscriptionResponse>(`${environment.api.url}subscription`);
  }

  public fetchAppRegistrations(): Pending<AppRegistration[]> {
    return this._apiService.get<AppRegistration[]>(`${environment.api.url}appregistrations/me`);
  }

  public fetchAppRegistration(objectId: Guid): Pending<AppRegistration> {
    return this._apiService.get<AppRegistration>(`${environment.api.url}appregistrations/${objectId}`);
  }

  public fetchAppRegistrationPasswords(objectId: Guid): Pending<Array<AppRegPasswordCredentials>> {
    return this._apiService.get<Array<AppRegPasswordCredentials>>(`${environment.api.url}appregistrations/${objectId}/secrets`);
  }

  public deleteAppRegistrationPassword(objectId: Guid, keyId: Guid): Pending<boolean> {
    return this._apiService.delete(`${environment.api.url}appregistrations/${objectId}/secrets/${keyId}`);
  }

  public deleteAppRegistration(objectId: Guid): Pending<boolean> {
    return this._apiService.delete(`${environment.api.url}appregistrations/${objectId}`);
  }

  public createAppRegistrationPassword(objectId: Guid,
    keyId: string,
    displayName: string,
    startDateTime: Date,
    endDateTime: Date): Pending<PasswordCredential> {
    return this._apiService.post(`${environment.api.url}appregistrations/${objectId}/secrets`,
    {
      KeyId: keyId,
      DisplayName: displayName,
      StartDateTime: startDateTime,
      EndDateTime: endDateTime
    });
  }

  public fetchMailExists(mail: string): Pending<number> {
    return this._apiService.get<number>(`${environment.api.url}users/${mail}/exists`);
  }

  public commitNewRequestToRepo(requestComment: string, requestSubscriptionName: string, requestTenant: string, requestContent: any): Pending<any> {
    return this._apiService.post<any>(`${environment.api.url}repos`,
      {
        Comment: requestComment,
        SubscriptionName: requestSubscriptionName,
        Tenant: requestTenant,
        Content: requestContent
      });
  }

  public autoCompleteUser(searchTerm: string): Pending<AutocompleteUser[]>{
    return this._apiService.get<AutocompleteUser[]>(`${environment.api.url}users?searchName=${searchTerm}`);
  }

  public fetchBuilds(): Pending<BuildResponse> {
    return this._apiService.get<BuildResponse>(`${environment.api.url}builds`);
  }

  public fetchBuild(buildid: number): Pending<Build> {
    return this._apiService.get<Build>(`${environment.api.url}builds/${buildid}`);
  }
}
