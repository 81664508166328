import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { DecimalPipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { MsalService } from '@azure/msal-angular';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';
import { AllowedResource } from 'src/app/_shared/_classes/allowed-resource';
import { Guid } from 'src/app/_shared/_classes/guid';
import { Language } from 'src/app/_shared/_classes/language';
import { Subscription, SubscriptionResponse } from 'src/app/_shared/_classes/subscription';
import { ErrorDialogComponent } from 'src/app/_shared/_components/_dialogs/error-dialog/error-dialog.component';
import { InfoDialogComponent } from 'src/app/_shared/_components/_dialogs/info-dialog/info-dialog.component';
import { LoadingDialogComponent } from 'src/app/_shared/_components/_dialogs/loading-dialog/loading-dialog.component';
import { RequestDialogComponent } from 'src/app/_shared/_components/_dialogs/request-dialog/request-dialog.component';
import { Pending } from 'src/app/_shared/_interfaces/pending';
import { DataService } from 'src/app/_shared/_services/data.service';
import { LandingZoneApiService } from 'src/app/_shared/_services/landing-zone-api.service';
import { LanguageService } from 'src/app/_shared/_services/language.service';
import { LoggingService } from 'src/app/_shared/_services/logging.service';
import { environment } from 'src/environments/environment';

import { Table } from 'primeng/table';
import { AutocompleteUser } from 'src/app/_shared/_classes/autocompleteUser';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';

export class StandardLoadData {
  name: string;
  shortcut: string;
}

export class Department extends StandardLoadData { }

export class ManagedBy extends StandardLoadData { }

export class Workload extends StandardLoadData { }

export class Stage extends StandardLoadData { }

export class ResourceGroup {
  environment: number;
  name: string;
  location: string;
}

export class AadGroup {
  environment: number;
  name: string;
  role: number;
  scope: number;
  members: string[];
  description?: string;
}

export function TRANSLATE(str: string) {
  return str;
}

@Component({
  templateUrl: './create-landing-zone.component.html',
  styleUrls: ['./create-landing-zone.component.scss']
})
export class CreateLandingZoneComponent implements OnInit {

  public isHandset$: Observable<boolean> = this._breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  @ViewChild('ressourceFilter') ressourceFilter!: ElementRef;

  public basicForm: FormGroup = new FormGroup({});
  public detailsForm: FormGroup = new FormGroup({});
  public permissionsForm: FormGroup = new FormGroup({});

  public defaultResourceGroups: ResourceGroup[];
  public displayedColumnsResourceGroups: string[] = ['name', 'location'];
  public defaultAadGroups: AadGroup[];
  public displayedColumnsAadGroups: string[] = ['name', 'scope', 'description'];
  public requestJson: Object;

  public loadDepartments: Pending<Department[]>;
  public loadManagedBys: Pending<ManagedBy[]>;
  public loadWorkloads: Pending<Workload[]>;
  public loadStages: Pending<Stage[]>;
  public loadAllowedResources: Pending<AllowedResource[]>;
  public loadSubscriptions: Pending<SubscriptionResponse>;
  public allSubscriptions: Subscription[];

  public requestJsonWasSuccessfullyCommitted: boolean = false;
  public requestJsonWasSuccessfullyCommittedFirstTry: boolean = true;

  public dSAllowedResourcesPN: AllowedResource[] = [];
  public selectedDSAllowedResourcesPN: AllowedResource[] = [];
  public dSAllowedResourcesLoadingPN: boolean = true;
  @ViewChild('allowedResourcesTablePN') allowedResourcesTablePN!: Table;

  private _snackBarHorizontalPosition: MatSnackBarHorizontalPosition = 'end';
  private _snackBarVerticalPosition: MatSnackBarVerticalPosition = 'top';

  private _loadingDialogRef: any;
  private _errorDialogRef: any;
  private _requestDialogRef: DynamicDialogRef;
  public _allStages: Stage[] = [];

  private _pipelineLoadingIsRunning: boolean = false;
  public managedByOptions: ManagedBy[] = [];
  public departmentOptions: Department[] = [];
  public workloadOptions: Workload[] = [];

  public userSuggestions: AutocompleteUser[] = [];

  constructor(
    private _breakpointObserver: BreakpointObserver,
    private _msalService: MsalService,
    private _formBuilder: FormBuilder,
    private _dataService: DataService,
    private _snackBar: MatSnackBar,
    private _dialogService: DialogService,
    private _languageService: LanguageService,
    private _translate: TranslateService,
    private _landingZoneApiService: LandingZoneApiService,
    private _loggingService: LoggingService,
    private _decimalPipe: DecimalPipe,
  ) { }

  public ngOnInit(): void {
    if (environment.enableDebug) {
      console.log(`Start: ngOnInit()`);
    }
    this.initializeAll();
    this.initializeLoadData();
    this.loadData();
  }

  public clear(table: Table) {
    this.ressourceFilter.nativeElement.value = '';
    table.clear();
  }

  public setThousandsSeparator(event: any): void {
    let curVal: string = this.detailsForm.get('annualBudget')?.value;
    let curNum: number | null = isNaN(Number.parseFloat(curVal)) ? null : this.getNumberOf(curVal);
    if (curNum && curNum > Number.MAX_SAFE_INTEGER) {
      curNum = Number.MAX_SAFE_INTEGER;
    }
    this.detailsForm.get('annualBudget')?.patchValue(this._decimalPipe.transform(curNum, '1.0', 'de-DE')?.replace(',', '.'));
  }

  private getNumberOf(val: string): number {
    if (typeof val === 'number') {
      return val;
    }

    return +(val.replace(/[^0-9]/g, ''));
  }

  public onStepChange(event: StepperSelectionEvent): void {
    // Only on last step (0, 1, 2)
    if (event.selectedIndex === 3) {
      this.generateRequestJson();
    }
  }

  public getRequestJsonStr(): string {
    return JSON.stringify(this.requestJson);
  }

  public openRequestJsonWasCopied(): void {
    // if (this._pipelineLoadingIsRunning) {
    this._snackBar.open(
      this._translate.instant('create_landing_zone.dialogs.request_copied'), //'Request was copied!',
      undefined,
      {
        duration: 1000,
        panelClass: ['rrps-snackbar']
      }
    );
    // }
  }

  public refreshPage(): void {
    location.reload();
  }

  public openSnackBarErrorSubscriptionExists(): void {
    if (this._pipelineLoadingIsRunning) {
      this._snackBar.open(
        this._translate.instant('create_landing_zone.dialogs.subscription_exists'),
        this._translate.instant('create_landing_zone.dialogs.dismiss'),
        {
          horizontalPosition: this._snackBarHorizontalPosition,
          verticalPosition: this._snackBarVerticalPosition,
          panelClass: ['rrps-snackbar']
        }
      );
    }
  }

  public createLandingZone(): void {
    if (environment.enableDebug) {
      console.log(`Start: createLandingZone()`);
    }
    if (
      this.basicForm.valid && this.detailsForm.valid
    ) {
      this._requestDialogRef = this._dialogService.open(
        RequestDialogComponent,
        {
          header: this._translate.instant('create_landing_zone.dialogs.landing_zone_creation.title'),
          data: {
            message: this._translate.instant('create_landing_zone.dialogs.landing_zone_creation.sure_paid_subscription')
          }
        });
      this._requestDialogRef.onClose.subscribe(
        (answer: any) => {
          if (answer) {
            if (environment.enableDebug) {
              console.log(`Info: createLandingZone() - User has agreed to create a paid subscription.`);
            }
            this._loadingDialogRef = this._dialogService.open(
              LoadingDialogComponent,
              {
                data: {
                  message: this._translate.instant('create_landing_zone.dialogs.landing_zone_creation.loading_save_configuration')
                }
              });
            this.commitRequestJson();
          }
        }
      );
    } else if (environment.enableDebug) {
      console.log(`Error: createLandingZone()`);
      console.log(`Log: createLandingZone()`);
      console.log(`Log: basicForm.valid: ${this.basicForm.valid}`);
      console.log(`Log: detailsForm.valid: ${this.detailsForm.valid}`);
    }
    if (environment.enableDebug) {
      console.log(`End: createLandingZone()`);
    }
  }

  public commitRequestJsonOnly(): void {
    if (environment.enableDebug) {
      console.log(`Start: commitRequestJsonOnly()`);
    }
    if (
      this.basicForm.valid && this.detailsForm.valid
    ) {
      this._requestDialogRef = this._dialogService.open(
        RequestDialogComponent,
        {
          data: {
            title: this._translate.instant('create_landing_zone.dialogs.landing_zone_creation.title'),
            message: this._translate.instant('create_landing_zone.dialogs.landing_zone_creation.sure_try_save_again')
          }
        });
      this._requestDialogRef.onClose.subscribe(
        (answer: any) => {
          if (answer) {
            if (environment.enableDebug) {
              console.log(`Info: commitRequestJsonOnly() - User has agreed to upload the configuration file again.`);
            }
            this._loadingDialogRef = this._dialogService.open(
              LoadingDialogComponent,
              {
                data: {
                  message: this._translate.instant('create_landing_zone.dialogs.landing_zone_creation.loading_save_configuration')
                }
              });
            this.commitRequestJson();
          }
        }
      );
    }
    if (environment.enableDebug) {
      console.log(`End: commitRequestJsonOnly()`);
    }
  }

  public applyFilterAllowedResourcesPN($event: any, matchMode: string) {
    this.allowedResourcesTablePN.filterGlobal(($event.target as HTMLInputElement)?.value, matchMode);
  }

  public keyPressAlpha(event: any): boolean {
    var inp = String.fromCharCode(event.keyCode);

    if (/[a-zA-Z]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  public onKeyUpApplicationName(): void {
    this.checkAppName();
  }

  public onChangeApplicationNumber(): void {
    this.checkAppName();
  }

  public onSelectionChangeDepartment(): void {
    this.checkAppName();
  }

  public checkAppName(): void {
    this.basicForm.controls['appName'].updateValueAndValidity();
    this.basicForm.controls['appNumber'].updateValueAndValidity();
    this.detailsForm.controls['department'].updateValueAndValidity();
    let subscriptionName = this.generateSubscriptionName();
    if (environment.enableDebug) {
      console.log(`Info: checkAppName() | SubscriptionName: ${subscriptionName}`);
    }
    this.allSubscriptions.forEach(
      subscription => {
        if (subscription.displayName == subscriptionName) {
          // EXISTS
          if (environment.enableDebug) {
            console.log(`Info: checkAppName() | SubscriptionName: ${subscriptionName} EXISTS`);
          }
          this.basicForm.controls['appName'].setErrors({ 'subscriptionNameExists': true });
          this.basicForm.controls['appNumber'].setErrors({ 'subscriptionNameExists': true });
          this.detailsForm.controls['department'].setErrors({ 'subscriptionNameExists': true });
          this.basicForm.controls['appNumber'].markAsTouched();
          this.openSnackBarErrorSubscriptionExists();
          return;
        }
      }
    );
  }

  public checkMailExists(input: any): void {
    if (!input.hasError('email')) {
      this._landingZoneApiService.fetchMailExists(input.value).data.subscribe(
        (response) => {
          this._loggingService.logInfo(response);
          if (response == 1) {
            input.setErrors({ 'emailDoesNotExist': false });
            input.updateValueAndValidity();
          } else {
            input.setErrors({ 'emailDoesNotExist': true });
          }
        },
        (error) => {
          this._loggingService.logError(error);
        }
      );
    }
  }

  public generateSubscriptionName(): string {
    let appNumberWithLeadingZero = '0' + this.basicForm.get('appNumber')!.value;
    appNumberWithLeadingZero = appNumberWithLeadingZero.substr(appNumberWithLeadingZero.length - 2);
    let appName = this.basicForm.get('appName')?.value;
    let appNameFull = String(appName).replace(/[ -/]/gi, ' ').replace(/(^\w|\s\w)/g, m => m.toUpperCase());
    let appNameShort = appNameFull.replace(/[ ]/gi, '').toUpperCase();
    return `AZ-SUB-RRPS-${appNameShort}-${appNumberWithLeadingZero}`;
  }

  private initializeAll(): void {
    this.defaultResourceGroups = [];
    this.defaultAadGroups = [];
    this.dSAllowedResourcesPN = [];
    this.requestJson = [];
    this.allSubscriptions = [];

    this.initializeBasicForm();
    this.initializeDetailsForm();
    // this.initializePermissionsForm();

    this.initializeLoadData();
  }

  private initializeLoadData(): void {
    this.loadDepartments = this._dataService.fetchData<Department[]>(`departments`);
    this.loadManagedBys = this._dataService.fetchData<ManagedBy[]>(`managed-bys`);
    this.loadWorkloads = this._dataService.fetchData<Workload[]>(`workloads`);
    this.loadStages = this._dataService.fetchData<Stage[]>(`stages`);
    this.loadAllowedResources = this._dataService.fetchData<AllowedResource[]>(`allowed-resources`);
    this.loadSubscriptions = this._landingZoneApiService.fetchSubscriptions();// this._dataService.fetchData<{ value: Subscription[] }>(`test/subscriptions`);
  }

  private loadData(): void {
    if (environment.enableDebug) {
      console.log(`Start: loadData()`);
    }
    this.loadDepartments.data.subscribe(
      (response) => {
        this.departmentOptions = response;
      }
    );
    this.loadManagedBys.data.subscribe(
      (response) => {
        this.detailsForm.patchValue({ managedBy: response[0].shortcut });
        this.managedByOptions = response;
        this.detailsForm.updateValueAndValidity();
      }
    );
    this.loadWorkloads.data.subscribe(
      (response) => {
        this.detailsForm.patchValue({ workload: response[0].shortcut });
        this.workloadOptions = response;
        this.detailsForm.updateValueAndValidity();
      }
    );
    this.loadStages.data.subscribe(
      (response) => {
        this._allStages = response;
        this.initializePermissionsForm();
      }
    );
    this.loadAllowedResources.data.subscribe(
      (response) => {
        this.dSAllowedResourcesPN = response;
        this.dSAllowedResourcesLoadingPN = false;
        if (environment.enableDebug) {
          console.log(`Start: loadAllowedResources`);
          console.log(this.dSAllowedResourcesLoadingPN);
          console.log(`End: loadAllowedResources`);
        }
      }
    );
    this.loadSubscriptions.data.subscribe(
      response => {
        this.allSubscriptions = response.value;
        if (environment.enableDebug) {
          console.log(`Start: loadSubscriptions()`);
          console.log(response);
          console.log(this.allSubscriptions);
          console.log(`End: loadSubscriptions()`);
        }
      }
    );
    this._languageService.currentLang.subscribe(
      (lang) => {
        if (localStorage.getItem(Language.LANG_LOCAL_STORAGE_ITEM) != lang.iso) {
          const delay = (ms: number) => new Promise(res => setTimeout(res, ms));
          const yourFunction = async () => {
            await delay(1000);
            this.initializeLoadData();
            this.loadData();
          };
          yourFunction();
        }
      }
    );
    if (environment.enableDebug) {
      console.log(`End: loadData()`);
    }
  }

  private initializeBasicForm(): void {
    this.basicForm = this._formBuilder.group(
      {
        appName: ['', [Validators.required]],
        appNumber: [1, [Validators.required, Validators.min(1), Validators.max(99)]],
        appDescription: ['', []],
        itServiceName: ['', [Validators.required]],
        serviceId: ['', []],
        itServiceOwner: ['', [Validators.required, Validators.email]],
        itServiceOwnerDeputy: ['', [Validators.required, Validators.email]]
      }

    );

    this.basicForm.get('appName')?.valueChanges
      .pipe(
        distinctUntilChanged() // it is needed because patchValue emits execution again
      ).subscribe(
        (value: string) => {
          this.basicForm.get('appName')?.patchValue(value.trim().toUpperCase());
        }
      );
  }

  private initializeDetailsForm(): void {
    this.detailsForm = this._formBuilder.group(
      {
        managedBy: ['', [Validators.required]],
        workload: ['', [Validators.required]],
        department: ['', [Validators.required]],
        annualBudget: [null, [Validators.required]],
      }
    );
  }

  private initializePermissionsForm(): void {
    let controls = {};

    controls['SUBSCRIPTION_Reader'] = new FormControl();
    controls['SUBSCRIPTION_Contributor'] = new FormControl();

    this._allStages.forEach(stage => {
      controls[stage.shortcut + '_Reader'] = new FormControl();
      controls[stage.shortcut + '_Contributor'] = new FormControl();
    });

    this.permissionsForm = this._formBuilder.group(controls);
  }

  private generateRequestJson(): void {
    if (environment.enableDebug) {
      console.log(`Start: generateRequestJson()`);
    }
    const now = new Date();
    const nowUtcTimestamp = new Date(now.getTime() + (now.getTimezoneOffset() * 60000));

    let appNumberWithLeadingZero = '0' + this.basicForm.get('appNumber')?.value;
    appNumberWithLeadingZero = appNumberWithLeadingZero.substr(appNumberWithLeadingZero.length - 2);

    let appName = this.basicForm.get('appName')?.value;
    let appNameFull = String(appName).replace(/[ -/]/gi, ' ').replace(/(^\w|\s\w)/g, m => m.toUpperCase());
    let appNameShort = appNameFull.replace(/[ ]/gi, '').toUpperCase();
    let subscriptionName = 'AZ-SUB-RRPS-' + appNameShort + '-' + appNumberWithLeadingZero;

    this.generateDefaultResourceGroups(appNameShort, appNumberWithLeadingZero);
    this.generateDefaultAadGroups(appNameShort, appNumberWithLeadingZero);

    this.requestJson = {
      Environment: null,
      Region: null,
      CompanyCode: null,
      DepartmentShort: this.detailsForm.get('department')?.value,
      AnnualBudgetPoundSterling: this.getNumberOf(this.detailsForm.get('annualBudget')?.value),
      ITServiceName: this.basicForm.get('itServiceName')?.value,
      ITServiceId: this.basicForm.get('serviceId')?.value,
      ITServiceOwner: this.basicForm.get('itServiceOwner')?.value,
      ITServiceOwnerDeputyEmail: this.basicForm.get('itServiceOwnerDeputy')?.value,
      ApplicationInstanceNumber: appNumberWithLeadingZero,
      ApplicationDescription: this.basicForm.get('appDescription')?.value,
      ApplicationNameShort: appNameShort,
      SubscriptionName: subscriptionName,
      SubscriptionId: null,
      Workload: this.detailsForm.get('workload')?.value,
      Stage: this._allStages[0].shortcut,
      Resource: this.detailsForm.get('managedBy')?.value,
      AddMonitoring: null,
      AddNetworkPeering: null,
      ResourceGroups: [],
      AadGroups: [],
      AllowedResourceTypes: [],
      Issuer: {
        FullName: this._msalService.instance.getActiveAccount()?.name,
        Email: this._msalService.instance.getActiveAccount()?.username,
        TenantId: this._msalService.instance.getActiveAccount()?.tenantId,
        LocalAccountId: this._msalService.instance.getActiveAccount()?.localAccountId,
        RequestDateUtc: nowUtcTimestamp,
      },
      Transaction: {
        CorrelationId: Guid.newGuid(),
        TimeStampUtc: nowUtcTimestamp
      }
    };

    this.defaultResourceGroups.forEach(
      (resourceGroup) => {
        this.requestJson['ResourceGroups'].push(
          {
            Environment: resourceGroup.environment,
            Name: resourceGroup.name,
            Location: resourceGroup.location
          }
        );
      }
    );

    this.defaultAadGroups.forEach(
      (aadGroup) => {
        this.requestJson['AadGroups'].push(
          {
            Environment: aadGroup.environment,
            Name: aadGroup.name,
            AadGroupRole: aadGroup.role,
            AadGroupScope: aadGroup.scope,
            GroupDescription: aadGroup.description,
            Members: aadGroup.members
          }
        );
      }
    );

    this.selectedDSAllowedResourcesPN.forEach(
      (allowedResource) => {
        allowedResource.types.forEach(
          (resourceType) => {
            this.requestJson['AllowedResourceTypes'].push(
              {
                "ResourceType": resourceType
              }
            );
          }
        );
      }
    );
    if (environment.enableDebug) {
      console.log(`End: generateRequestJson()`);
    }
  }

  private generateDefaultResourceGroups(appNameShort: string, appNumberWithLeadingZero: string): void {
    if (environment.enableDebug) {
      console.log(`Start: generateDefaultResourceGroups()`);
    }
    this.defaultResourceGroups = [];

    let rgName = `AZEUW-RG-${appNameShort}-MGMT-${appNumberWithLeadingZero}`;
    this.defaultResourceGroups.push(
      {
        environment: -1,
        name: rgName,
        location: `West Europe`
      }
    );


    let i = 0;
    this._allStages.forEach(
      (stage) => {
        let rgName = `AZEUW-RG-${appNameShort}-${stage.shortcut}-${appNumberWithLeadingZero}`;
        this.defaultResourceGroups.push(
          {
            environment: i,
            name: rgName,
            location: `West Europe`
          }
        );
        ++i;
      }
    );
    if (environment.enableDebug) {
      console.log(`End: generateDefaultResourceGroups()`);
    }
  }

  private generateDefaultAadGroups(appNameShort: string, appNumberWithLeadingZero: string) {
    if (environment.enableDebug) {
      console.log(`Start: generateDefaultAadGroups()`);
    }
    this.defaultAadGroups = [];

    let subAadGroupNamePart = `AZSG_${environment.tenant.abbreviation}_-_AZ-SUB-RRPS-${appNameShort}-${appNumberWithLeadingZero}`




    let permissions = {};

    permissions['SUBSCRIPTION'] = {
      Reader: this.permissionsForm.get('SUBSCRIPTION_Reader')?.value ? [this.permissionsForm.get('SUBSCRIPTION_Reader')?.value?.map((v: AutocompleteUser) => v.email)] : [],
      Contributor: this.permissionsForm.get('SUBSCRIPTION_Contributor')?.value ? [this.permissionsForm.get('SUBSCRIPTION_Contributor')?.value?.map((v: AutocompleteUser) => v.email)] : []
    }

    this._allStages.forEach(stage => {
      permissions[stage.shortcut] = {
        Reader: this.permissionsForm.get(stage.shortcut + '_Reader')?.value ? [this.permissionsForm.get(stage.shortcut + '_Reader')?.value?.map((v: AutocompleteUser) => v.email)] : [],
        Contributor: this.permissionsForm.get(stage.shortcut + '_Contributor')?.value ? [this.permissionsForm.get(stage.shortcut + '_Contributor')?.value?.map((v: AutocompleteUser) => v.email)] : []
      }
    });



    this.defaultAadGroups.push(
      {
        environment: -1,
        name: `${subAadGroupNamePart}_-_-_R`,
        role: 0,
        scope: 0,
        description: `${subAadGroupNamePart} Reader`,
        members: this.permissionsForm.get('SUBSCRIPTION_Reader')?.value ? this.permissionsForm.get('SUBSCRIPTION_Reader')?.value?.map((v: AutocompleteUser) => {
          return {
            ObjectId: v.objectId,
            email: v.email
          }
        }
        ) : []
      }
    );
    this.defaultAadGroups.push(
      {
        environment: -1,
        name: `${subAadGroupNamePart}_-_-_C`,
        role: 1,
        scope: 0,
        description: `${subAadGroupNamePart} Contributor`,
        members: this.permissionsForm.get('SUBSCRIPTION_Contributor')?.value ? this.permissionsForm.get('SUBSCRIPTION_Contributor')?.value?.map((v: AutocompleteUser) => {
          return {
            ObjectId: v.objectId,
            email: v.email
          };
        }) : []
      }
    );
    this.defaultAadGroups.push(
      {
        environment: -1,
        name: `${subAadGroupNamePart}_-_-_O`,
        role: 2,
        scope: 0,
        description: `${subAadGroupNamePart} Owner`,
        members: []
      }
    );

    let i = 0;
    this._allStages.forEach(
      (stage) => {
        let rgName = `AZEUW-RG-${appNameShort}-${stage.shortcut}-${appNumberWithLeadingZero}`;
        let aadGroupPrefix = `AZSG_${environment.tenant.abbreviation}_-_-_${rgName}_-_`;
        this.defaultAadGroups.push(
          {
            environment: i,
            name: `${aadGroupPrefix}R`,
            role: 0,
            scope: 1,
            description: `${rgName} Reader`,
            members: this.permissionsForm.get(stage.shortcut + '_Reader')?.value ? this.permissionsForm.get(stage.shortcut + '_Reader')?.value?.map((v: AutocompleteUser) => {
              return {
                ObjectId: v.objectId,
                email: v.email
              };
            }) : []
          }
        );
        this.defaultAadGroups.push(
          {
            environment: i,
            name: `${aadGroupPrefix}C`,
            role: 1,
            scope: 1,
            description: `${rgName} Contributor`,
            members: this.permissionsForm.get(stage.shortcut + '_Contributor')?.value ? this.permissionsForm.get(stage.shortcut + '_Contributor')?.value?.map((v: AutocompleteUser) => {
              return {
                ObjectId: v.objectId,
                email: v.email
              };
            }) : []
          }
        );
        this.defaultAadGroups.push(
          {
            environment: i,
            name: `${aadGroupPrefix}O`,
            role: 2,
            scope: 1,
            description: `${rgName} Owner`,
            members: []
          }
        );
        ++i;
      }
    );
    if (environment.enableDebug) {
      console.log(`End: generateDefaultAadGroups()`);
    }
  }

  private commitRequestJson(): void {
    if (environment.enableDebug) {
      console.log(`Start: commitRequestJson()`);
    }
    if (!this.requestJsonWasSuccessfullyCommitted) {
      this._landingZoneApiService.commitNewRequestToRepo(
        this.requestJson['Transaction']['CorrelationId'],
        this.requestJson['SubscriptionName'],
        this._msalService.instance.getActiveAccount()!.tenantId,
        JSON.stringify(this.requestJson, null, 2)
      ).data.subscribe(
        () => {
          this.requestJsonWasSuccessfullyCommitted = true;
          this.requestJsonWasSuccessfullyCommittedFirstTry = false;
          this._loadingDialogRef.close();
          this._dialogService.open(InfoDialogComponent, {
            data: {
              title: this._translate.instant('create_landing_zone.dialogs.landing_zone_creation.success.title'),
              message: this._translate.instant('create_landing_zone.dialogs.landing_zone_creation.success.message'),
              optionOne: {
                value: 'accepted',
                display: this._translate.instant('create_landing_zone.dialogs.accept')
              }
            }
          });
        },
        (error) => {
          this.requestJsonWasSuccessfullyCommittedFirstTry = false;
          this._loggingService.logError(error);
          this._loadingDialogRef.close();
          this._errorDialogRef = this._dialogService.open(ErrorDialogComponent, {
            data: {
              title: this._translate.instant('general.errors.title'),
              message: this._translate.instant('create_landing_zone.review_create.errors.upload_request')
            }
          }
          );
        }
      );
    } else {
      console.log(`Error: commitRequestJson()`);
      console.log(`Log: requestJsonWasSuccessfullyCommitted: ${this.requestJsonWasSuccessfullyCommitted}`);
      this._loadingDialogRef.close();
      this._errorDialogRef = this._dialogService.open(ErrorDialogComponent, {
        data: {
          title: 'Error',
          message: 'Error commit configuration file to Azure DevOps.'
        }
      }
      );
    }
    if (environment.enableDebug) {
      console.log(`End: commitRequestJson()`);
    }
  }

  selectDependencies(ressource: AllowedResource) {
    let dependencies = this.dSAllowedResourcesPN.filter(r => ressource.dependencies.includes(r.id));

    dependencies.forEach(d => {
      if (this.selectedDSAllowedResourcesPN.find(res => res.id === d.id) === undefined) {
        this.selectedDSAllowedResourcesPN.push(d);
        this.selectDependencies(d);
      }
    });

    this.selectedDSAllowedResourcesPN = [...this.selectedDSAllowedResourcesPN]
  }

  onRowSelect(event: any) {
    this.selectDependencies(event.data);
    this.allowedResourcesTablePN.updateSelectionKeys()
  }

  search(event: any) {
    let searchTerm = event.query;
    this._landingZoneApiService.autoCompleteUser(searchTerm).data.subscribe((users) => {
      this.userSuggestions = users;
    });
  }

  onLookupSelected(event: any, fieldname: string) {
    this.basicForm.patchValue({ [fieldname]: this.basicForm.controls[fieldname].value.email });
    this.checkMailExists(this.basicForm.get(fieldname));
  }

  isDependency(allowedResource: AllowedResource): boolean {
    let isDep = false;

    this.selectedDSAllowedResourcesPN.forEach(
      (res) => {
        if (res.dependencies.includes(allowedResource.id)) {
          isDep = true;
        }
      }
    );

    return isDep;
  }


  getPermissionValues(fieldName: string) {
    return this.permissionsForm.get(fieldName)?.value?.map((v: AutocompleteUser) => v.email).join(', ')
  }

}
