import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { defer, ReplaySubject } from 'rxjs';
import { catchError, retry, tap } from 'rxjs/operators';
import { Pending, PendingStatus } from '../../_interfaces/pending';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  public constructor(
    private _http: HttpClient
  ) { }

  public get<T>(url: string): Pending<T> {
    const status = new ReplaySubject<PendingStatus>();
    const request = this._http.get<T>(url)
      .pipe(
        retry(2),
        catchError(
          error => {
            status.next(PendingStatus.ERROR);
            throw error;
          },
        ),
        tap(
          response => {
            status.next(PendingStatus.SUCCESS);
            return response;
          }
        )
      );

    const data = defer(
      () => {
        status.next(PendingStatus.LOADING);
        return request;
      }
    );

    return { data, status };
  }

  public post<T>(url: string, body: any): Pending<T> {
    const status = new ReplaySubject<PendingStatus>();
    const request = this._http.post<T>(url, body)
      .pipe(
        retry(2),
        catchError(
          error => {
            status.next(PendingStatus.ERROR);
            throw error;
          },
        ),
        tap(
          response => {
            status.next(PendingStatus.SUCCESS);
            return response;
          }
        )
      );

    const data = defer(
      () => {
        status.next(PendingStatus.LOADING);
        return request;
      }
    );

    return { data, status };
  }


  public delete<T>(url: string): Pending<T> {
    const status = new ReplaySubject<PendingStatus>();
    const request = this._http.delete<T>(url)
      .pipe(
        retry(2),
        catchError(
          error => {
            status.next(PendingStatus.ERROR);
            throw error;
          },
        ),
        tap(
          response => {
            status.next(PendingStatus.SUCCESS);
            return response;
          }
        )
      );

    const data = defer(
      () => {
        status.next(PendingStatus.LOADING);
        return request;
      }
    );

    return { data, status };
  }

}
