<mat-toolbar style="height: 12.5%; position: fixed; z-index: 2;" color="primary" class="mat-elevation-z2">
  <div style="position: relative; width: 100%; height: 90%;">
    <div style="height: 60%;">
      <img src="/assets/logos/rr_white.svg" height="100%" style="margin-right: 10px;">
      <div style="height: 100%; float: right;">
        <a href="https://www.mtu-solutions.com/">
          <img src="/assets/logos/mtu_white.svg" height="50%" style="margin-right: 10px; margin-top: 12.5%;">
        </a>
        <br>

        <button mat-icon-button type="button" (click)="display = true" aria-label="Help menu">
          <mat-icon aria-label="Side nav toggle icon">
            help
          </mat-icon>
        </button>


        <button mat-icon-button type="button" [matMenuTriggerFor]="menu" aria-label="User menu">
          <mat-icon aria-label="Side nav toggle icon">
            person
          </mat-icon>
        </button>


        <mat-menu #menu="matMenu">
          <div style="padding-left: 16px;">
            <h3>
              {{ activeAccount?.name }}</h3>
          </div>
          <button mat-menu-item>
            {{ activeAccount?.username }}
          </button>
          <button mat-menu-item (click)="logout()">
            <span>{{ 'general.logout' | translate }}</span>
          </button>
        </mat-menu>
      </div>
    </div>
    <br>
    <div style="position: absolute; bottom: 0;">
      <button mat-icon-button (click)="toggleMenu()" type="button" aria-label="Toggle sidenav"
        style="margin-right: 10px;">
        <mat-icon aria-label="Side nav toggle icon" class="icon-display">
          menu
        </mat-icon>
      </button>
      <span class="title">
        {{ 'general.title' | translate }}
      </span>

    </div>
  </div>
</mat-toolbar>
<mat-sidenav-container style="top: 12.5%; height: calc(100vh - 12.5%);" autosize>
  <mat-sidenav #sidenav [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'" class="mat-elevation-z2" [opened]="(isHandset$ | async) === false">
    <!--[opened]="(isHandset$ | async) === false"-->
    <mat-nav-list [style]="{ 'max-width' : isExpanded ? '500px' : '60px'} ">
      <!--<mat-nav-list style="width: 200px;"> style="width: auto;"-->

      <a mat-list-item routerLink="/home" routerLinkActive="router-link-active">
        <mat-icon aria-label="Side nav toggle icon" class="mr-2">
          home
        </mat-icon>
        <span *ngIf="isExpanded">{{ 'home.start' | translate }}</span>
      </a>

      <a mat-list-item routerLink="/create-landing-zone" routerLinkActive="router-link-active"
        *ngIf="isLandingZoneUser">
        <mat-icon aria-label="Side nav toggle icon" class="mr-2">
          flight_land
        </mat-icon>
        <span *ngIf="isExpanded">{{ 'create_landing_zone.title' | translate }}</span>
      </a>

      <a mat-list-item routerLink="/manage-app-reg" routerLinkActive="router-link-active" *ngIf="isAppRegUser">
        <mat-icon aria-label="Side nav toggle icon" class="mr-2">
          vpn_key
        </mat-icon>
        <span *ngIf="isExpanded">{{ 'manage_app_reg.title' | translate }}</span>
      </a>

      <!-- <div style="position: absolute; bottom: 0; left: 0; width: 100%;">
        <a mat-list-item routerLink="/about" routerLinkActive="router-link-active"> {{ 'about.title' | translate }} </a>
      </div> -->
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>

<p-sidebar [(visible)]="display" position="right" style="height: 100%" [showCloseIcon]="false">

  <div style="display: flex; flex-direction: column; height: 100%; justify-content: space-between">
    <div>
      <div class="helpHeading">Help</div>
      <div class="helpText" style="margin-bottom: 3rem;">
        If you have any problems using this portal or if you need assistance, please contact the WorkFlow mailboxes
        listed
        under "Contact" or check our <a href="https://one.org-intra.net/com/50257/SitePages/Self-Service-Center.aspx" target="_blank">HowTo/FAQ</a> site.
      </div>

      <div class="helpHeading" style="margin-top: 3rem;">Contact</div>
      <div class="contactText">
        For failures or problems:
      </div>
      <div class="mailLink">
        <a href="mailto:azure.incident@ps.rolls-royce.com">
          azure.incident@ps.rolls-royce.com
        </a>
        <mat-icon class="ml-2">
          mail
        </mat-icon>
      </div>
      <div class="contactText">
        For questions or change requests:
      </div>
      <div class="mailLink">
        <a href="mailto:azure.request@ps.rolls-royce.com">
          azure.request@ps.rolls-royce.com
        </a>
        <mat-icon class="ml-2">
          mail
        </mat-icon>
      </div>
    </div>
    <div>
      <span class="helpHeading">About</span>
      <div class="aboutText">
        {{ 'general.version' | translate }}: {{ version }} <br> {{ 'general.copyright' | translate }}
      </div>
    </div>



  </div>
</p-sidebar>
