<h2 mat-dialog-title>
    {{ config.data.title }}
</h2>
<mat-dialog-content class="mat-typography" style="white-space: pre-wrap;">{{ config.data.message }}</mat-dialog-content>
<br>
<mat-dialog-actions align="end">
    <button (click)="closeDialog()" mat-raised-button [mat-dialog-close]="config.data.optionOne.value" color="primary" *ngIf="config.data.optionOne">
        {{ config.data.optionOne.display }}
    </button>
</mat-dialog-actions>
