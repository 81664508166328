<h1>{{ pageTitle }}</h1>
<div class="create-container">
  <form [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)" class="form">
    <div style="display: flex; flex-direction: row; ">
      <span class="p-float-label" style="margin-right: 1em;">
        <input type="text" id="secretdescription" pInputText formControlName="secretDisplayName">
        <label for="secretdescription">Secret Description</label>
      </span>

      <span class="p-float-label" style="margin-right: 1em;">
        <p-dropdown [options]="months" formControlName="monthSelect" optionLabel="name" optionValue="value"
        [showClear]="true"></p-dropdown>
        <label for="secretdescription">Expires in</label>
      </span>
    </div>

    <div class="form-element">
      <button pButton pRipple type="submit" class="button p-button-raised" [disabled]="!formGroup.valid">Create</button>
      <button pButton pRipple type="submit" class="button p-button-raised back-button"
        [routerLink]="['/manage-app-reg', appReg?.id]">
        <i class="pi pi-angle-left"></i> Back
      </button>
    </div>
  </form>
</div>
