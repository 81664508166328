import { CreateLandingZoneComponent } from './create-landing-zone.component';
import {MsalGuard} from "@azure/msal-angular";
import {RoleGuardService} from "../role-guard-service";
import auth from "../auth-config.json";

export const CREATE_LANDING_ZONE_ROUTES = [
    {
      path: '',
      component: CreateLandingZoneComponent,
      canActivate: [
        MsalGuard,
        RoleGuardService
      ],
      data: {
        expectedRole: auth.roles.LandingZoneUser,
        expectedTenants: auth.Tenants
      }
    }
];
