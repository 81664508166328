import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateLandingZoneComponent } from './create-landing-zone.component';

import { MaterialModule } from 'src/app/material.module';

// import ngx-translate and the http loader
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule} from '@angular/common/http';

import { DecimalPipe } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PrimeNgModule } from '../../primeng.module';
import { StagePermissionsComponent } from './stage-permissions/stage-permissions.component';


@NgModule({
  declarations: [
    CreateLandingZoneComponent,
    StagePermissionsComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    // ngx-translate and the loader module
    HttpClientModule,
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    }),
    FormsModule,
    ReactiveFormsModule,
    PrimeNgModule
  ],
  providers: [
    DecimalPipe
  ]
})
export class CreateLandingZoneModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
