import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';

import auth from '../auth-config.json';

type IdTokenClaims = {
  roles: string[];
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(
    private _msalService: MsalService,
  ) { }

  ngOnInit(): void {
  }

  get isAppRegUser(): boolean {
    if (this._msalService.instance.getAllAccounts() === null) {
      return false;
    }
    if (this._msalService.instance.getAllAccounts().length == 0) {
      return false;
    }

    return HomeComponent.checkRole(this._msalService.instance.getAllAccounts()[0], auth.roles.AppRegistrationUser);
  }

  get isLandingZoneUser(): boolean {
    if (this._msalService.instance.getAllAccounts() === null) {
      return false;
    }
    if (this._msalService.instance.getAllAccounts().length == 0) {
      return false;
    }

    return HomeComponent.checkRole(this._msalService.instance.getAllAccounts()[0], auth.roles.LandingZoneUser);
  }

  private static checkRole(accountInfo: AccountInfo, role: string): boolean {
    debugger;
    let claims = accountInfo.idTokenClaims as IdTokenClaims;
    return claims.roles ? claims.roles.includes(role) : false;
  }

}
