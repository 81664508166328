import { LoggingLevel } from "src/app/_shared/_classes/logging-level";
import { Environment } from "./_interfaces/environment";

export const environment: Environment = {
    production: false,
    stage: 'int',
    stdLang: 'en',
    enableDebug: true,
    logging: {
      level: LoggingLevel.Info
    },
    tenant: {
        abbreviation: 'OCT'
    },
    api: {
        url: 'https://azureapi-test.mtu-online.com/api/',
        scopes: ['api://19782553-0e0e-434f-83ee-a182450a5b7e/access_as_user']
    },
    msal: {
        clientId: 'aaeff4f9-be76-426b-abaf-b20805c5933d',
        authority: 'https://login.microsoftonline.com/common',
        redirectUri: '/'
    }
};
