import { Observable, Subject } from 'rxjs';

export enum PendingStatus {
    LOADING,
    SUCCESS,
    ERROR
};

export interface Pending<T> {
    status: Observable<PendingStatus>;
    data: Observable<T>;
    progress?: Subject<number>;
};
