import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialModule } from 'src/app/material.module';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { DecimalPipe } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ManageAppRegComponent } from './manage-app-reg.component';
import { AppRegDetailComponent } from './app-reg-detail.component';
import { AppRegCreateSecretComponent } from './app-reg-create-secret.component';
import { AppRegSecretDialogComponent } from './app-reg-secret-dialog.component';
import { RouterModule } from '@angular/router';

import { PrimeNgModule } from '../../primeng.module';

@NgModule({
  declarations: [
    ManageAppRegComponent,
    AppRegDetailComponent,
    AppRegCreateSecretComponent,
    AppRegSecretDialogComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    FormsModule,
    ReactiveFormsModule,
    PrimeNgModule
  ],
  providers: [
    DecimalPipe
  ]
})
export class ManageAppRegModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
